import { Component, Inject, NgZone, PLATFORM_ID, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-top-department-streaks',
  templateUrl: './top-department-streaks.component.html',
  styleUrls: ['./top-department-streaks.component.scss']
})
export class TopDepartmentStreaksComponent implements OnInit {
  private chart: am4charts.XYChart;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      am4core.options.autoSetClassName = true;

      let chart = am4core.create("streaksgraph", am4charts.XYChart);
      // Add data
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.paddingRight = 5;
      chart.paddingLeft = 5;
      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

      var colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.4;

      chart.data = [
        {
          name: "Marketing",
          fromDay: 0,
          toDay: 7,
          color: "#33EAC8"
        },
        {
          name: "Sales",
          fromDay: 0,
          toDay: 3,
          color: "#FEE388"
        },
        {
          name: "Sales",
          fromDay: 5,
          toDay: 7,
          color: "#FEE388"
        },

        {
          name: "Finance",
          fromDay: 4,
          toDay: 7,
          color: "#3EA9F5"
        },
        {
          name: "Design",
          fromDay: 2,
          toDay: 6,
          color: "#4C5A6B"
        },
        {
          name: "Admin",
          fromDay: 1,
          toDay: 3,
          color: "#90A3BC"
        }
      ];

      var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.maxWidth = 0;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.line.disabled = true;
      

      var dayAxis = chart.xAxes.push(new am4charts.ValueAxis());
      dayAxis.renderer.minGridDistance = 30;
      dayAxis.maxPrecision = 0;
      dayAxis.min = 0;
      dayAxis.max = 7;
      dayAxis.renderer.line.disabled = true;
      
      var series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.height = 10;
      //series1.columns.template.tooltipText = "{name}: {openDateX} - {dateX}";

      series1.dataFields.openValueX = "fromDay";
      series1.dataFields.valueX = "toDay";
      series1.dataFields.categoryY = "name";
      series1.columns.template.propertyFields.fill = "color"; // get color from data
      series1.columns.template.propertyFields.stroke = "color";
      series1.columns.template.strokeOpacity = 1;

      series1.columns.template.column.cornerRadiusTopLeft = 10;
      series1.columns.template.column.cornerRadiusTopRight = 10;
      series1.columns.template.column.cornerRadiusBottomLeft = 10;
      series1.columns.template.column.cornerRadiusBottomRight = 10;

      this.chart = chart;
    });
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
