import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.scss']
})
export class SnapshotComponent implements OnInit {
  loaded: boolean = false;
  activeUsers: number;
  averageGrowthScore: string;
  averageEngagement: string;
  showSnapshot: boolean = false;
  userVsCompany: number;
  gsVsCompany: number;
  engagementVsCompany: number;

  @Input() isCompany: boolean;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() { }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportInfo => {
      if(reportInfo !== null){
        if(
          this.isCompany && 
          this.getTodaysItem(reportInfo.comanyActiveUserCount) !== undefined &&
          this.getTodaysItem(reportInfo.comanyAverageGrowthScore) !== undefined &&
          this.getTodaysItem(reportInfo.comanyAverageEngagement) !== undefined
        ){
          this.activeUsers = this.getTodaysItem(reportInfo.comanyActiveUserCount);
          this.averageGrowthScore = parseFloat(this.getTodaysItem(reportInfo.comanyAverageGrowthScore)).toFixed(2);
          this.averageEngagement = parseFloat(this.getTodaysItem(reportInfo.comanyAverageEngagement)).toFixed(2);
          this.showSnapshot = (this.activeUsers > 0) ? true : false;
        }else if(
          this.isCompany && 
          this.getTodaysItem(reportInfo.comanyActiveUserCount) === undefined &&
          this.getTodaysItem(reportInfo.comanyAverageGrowthScore) === undefined &&
          this.getTodaysItem(reportInfo.comanyAverageEngagement) === undefined
        ){
          this.activeUsers = this.getTodaysItem(reportInfo.activeUserCount);
          this.averageGrowthScore = parseFloat(this.getTodaysItem(reportInfo.averageGrowthScore)).toFixed(2);
          this.averageEngagement = parseFloat(this.getTodaysItem(reportInfo.averageEngagement)).toFixed(2);
          this.showSnapshot = (this.activeUsers > 0) ? true : false;
        }else{
          this.activeUsers = this.getTodaysItem(reportInfo.activeUserCount);
          this.averageGrowthScore = parseFloat(this.getTodaysItem(reportInfo.averageGrowthScore)).toFixed(2);
          this.averageEngagement = parseFloat(this.getTodaysItem(reportInfo.averageEngagement)).toFixed(2);
          this.showSnapshot = (this.getTodaysItem(reportInfo.comanyActiveUserCount) && this.activeUsers > 0) ? true : false;
        }

        // work out VsCompany
        if(this.activeUsers > 0 && this.getTodaysItem(reportInfo.comanyActiveUserCount)){
          this.userVsCompany = (this.activeUsers / this.getTodaysItem(reportInfo.comanyActiveUserCount)) * 100;
          this.userVsCompany = Math.round((this.userVsCompany + Number.EPSILON) * 100) / 100;
        }
        if(this.getTodaysItem(reportInfo.averageGrowthScore) && this.getTodaysItem(reportInfo.comanyAverageGrowthScore)){
          this.gsVsCompany = ((this.getTodaysItem(reportInfo.averageGrowthScore) - this.getTodaysItem(reportInfo.comanyAverageGrowthScore)) / this.getTodaysItem(reportInfo.comanyAverageGrowthScore)) * 100;
          this.gsVsCompany = Math.round((this.gsVsCompany + Number.EPSILON) * 100) / 100;
        }
        if(this.getTodaysItem(reportInfo.averageEngagement) && this.getTodaysItem(reportInfo.comanyAverageEngagement)){
          this.engagementVsCompany = ((this.getTodaysItem(reportInfo.averageEngagement) - this.getTodaysItem(reportInfo.comanyAverageEngagement)) / this.getTodaysItem(reportInfo.comanyAverageEngagement)) * 100;
          this.engagementVsCompany = Math.round((this.engagementVsCompany + Number.EPSILON) * 100) / 100;
        }
      }else{
        this.showSnapshot = false;
      }
    });
  }

  getLastObjectItem(obj: any){
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null){
      return obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    }else{
      return obj;
    }
  }

  getTodaysItem(obj: any){
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null){
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return obj[yyyy+'-'+mm+'-'+dd];
    }else{
      return obj;
    }
  }

}