import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

export interface UsageUserData {
  name: string;
  division_department: string;
  country_region: string;
  date_enrolled: string;
  months_since_enrolled: string;
  level: string;
  total_progress: string;
  droplets_week: string;
  growsthscore: string;
  growthscore_varriance: string;
  work_days_since_last_login: string;
}


@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements AfterViewInit {
  users = [];
  displayedColumns: string[] = [
    'count',
    'name', 
    'division_department', 
    'country_region', 
    'date_enrolled', 
    'months_since_enrolled', 
    'level', 
    'total_progress', 
    'droplets_week',
    'growsthscore',
    'work_days_since_last_login'
  ];
  dataSource: MatTableDataSource<UsageUserData>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() {
    // Create 100 users
    //const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    const $this = this;

    this.reportInfo$
    .subscribe(reportInfo => {
        if(
          reportInfo !== null 
          && reportInfo.users !== undefined
        ){
          this.users = Object.keys(reportInfo.users).map(function(key, index) {
            let variance = 0;
            let country_region = (reportInfo.users[key].country) ?  reportInfo.users[key].country+' - ' : '';
            country_region += reportInfo.users[key].region;
            let division_department = (reportInfo.users[key].division) ? reportInfo.users[key].division+' - ' : '';
            division_department += reportInfo.users[key].department;
            if($this.getLastObjectItem(reportInfo.averageGrowthScore)){
              variance = ((reportInfo.users[key].growthscore - $this.getLastObjectItem(reportInfo.averageGrowthScore)) / $this.getLastObjectItem(reportInfo.averageGrowthScore)) * 100;
              variance = Math.round((variance + Number.EPSILON) * 100) / 100;
            }else if(!$this.getLastObjectItem(reportInfo.averageGrowthScore) && reportInfo.comanyAverageGrowthScore){
              variance = ((reportInfo.users[key].growthscore - reportInfo.comanyAverageGrowthScore) / reportInfo.comanyAverageGrowthScore) * 100;
              variance = Math.round((variance + Number.EPSILON) * 100) / 100;
            }
            return {
              name: reportInfo.users[key].firstname+' '+reportInfo.users[key].lastname,
              email: reportInfo.users[key].email,
              division_department: division_department,
              country_region: country_region,
              date_enrolled: reportInfo.users[key].regdate.slice(0,10),
              months_since_enrolled: reportInfo.users[key].monthsonmygrow,
              level: reportInfo.users[key].level,
              total_progress: reportInfo.users[key].dropletsCompleted,
              droplets_completed: reportInfo.users[key].dropletsCompleted,
              droplets_week: reportInfo.users[key].engagement,
              growsthscore: reportInfo.users[key].growthscore,
              growthscore_variance: variance,
              work_days_since_last_login: reportInfo.users[key].workdayssincelogin
            }
          });
          // sort by progress
          this.users.sort(function(a, b) {
            return b.droplets_completed - a.droplets_completed;
          });
          // Assign the data to the data source for the table to render
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  cellClass(perc: number){
    if(perc == 0){
      return 'grey';
    }else if(perc <= 50){
      return 'yellow';
    }else if(perc > 50 && perc < 100){
      return 'green';
    }else if(perc == 100){
      return 'blue';
    }
  }

  getLastObjectItem(obj: any){
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null){
      return obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    }else{
      return obj;
    }
  }

}