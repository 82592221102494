import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService){
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = this.authService.getToken();

        if (idToken) {
            const cloned = req.clone({
                //headers: req.headers.set("Authorization",
                //    "Bearer " + idToken)
                setHeaders: { 
                    'X-Api-Key': '80EE570B4D6C25FA88FE47199666C8BC',
                    'X-Token': idToken
                }
            });

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}