import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { UserInfoPayload, UserStateModel, Company } from '../interfaces/app.interfaces';

export class UpdateUserInfo {
    static readonly type = '[User] update user info';
    constructor(public payload: UserInfoPayload) { }
}

//state
@State<UserStateModel>({
  name: 'reportinguser',
  defaults: {
    id: '',
    first_name: '',
    last_name: '',
    level: '',
    email: '',
    companies: []
  }
})
export class UserState {
  constructor() { }

  @Selector()
  public static user(state: UserStateModel) {
    return state;
  }
  
  @Action(UpdateUserInfo)
  updateUserInfo(ctx: StateContext<UserStateModel>, action: UpdateUserInfo) {
    ctx.patchState({
      id: action.payload.id,
      first_name: action.payload.first_name,
      last_name: action.payload.last_name,
      level: action.payload.level,
      email: action.payload.email,
      companies: action.payload.companies
    });
  }

}