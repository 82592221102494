import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UpdateUserInfo, UserState } from 'src/app/state/user.state';
import { UserService } from 'src/app/services/user.service';
import { Company, UserStateModel } from 'src/app/interfaces/app.interfaces';
import { ReportState, UpdateCompaniesHierarchy, UpdateSelectedCompany } from 'src/app/state/report.state';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form:FormGroup;
  errorMessage: string;
  companies: Company[];
  loggedIn: boolean = false;

  @Select(UserState.user) user$: Observable<UserStateModel>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<Company>;

  private ngUnsubscribe = new Subject();

  constructor(
    private fb:FormBuilder, 
    private router: Router,
    private authService: AuthService,
    private store: Store,
    private userService: UserService,
    private reportService: ReportService
  ) {
    this.form = this.fb.group({
      email: ['',Validators.required],
      password: ['',Validators.required]
    });
  }

  ngOnInit() {
    // if we have user data and selected company > redirect to report
    // if we have user data and NO selected company and user is a Company level user > show company selector
    combineLatest(this.user$, this.selectedCompany$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(
      ([user, selectedCompany]) => {
        if(this.authService.getUserid()){
          // user has id so must be logged in
          this.loggedIn = true;
          if(selectedCompany === null){
            //user does not have a company selected
            this.errorMessage = 'Retrieving your access details...';
            this.getUserCompanies();
          }else if(selectedCompany !== null){
            // user has a company selected so go to report
            this.router.navigateByUrl('/report');
          }else{
            this.errorMessage = 'Your user does not have access to any Company';
          }
        }
      }
    );

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    this.errorMessage = (error.error.message) ? error.error.message : 'Something went wrong with your request, please try again.';
    return this.errorMessage;
  }

  login() {
    const val = this.form.value;
    this.errorMessage = '';

      if (val.email && val.password) {
          this.authService.login(val.email, val.password)
          .pipe( 
            tap(),
            catchError(err => this.errorHandler(err)) 
          )
          .subscribe(
              res => {
                //got a response
                if(res.status){
                  // successful and token sent back
                  this.authService.setSession(res);
                  if(res.data.id){
                    this.getUserData(res.data.id);
                  }
                }else{
                  //unsuccessful for some reason
                  this.errorMessage = (this.errorMessage === '') ? 'Something went wrong with your request, please try again.' : this.errorMessage;
                }
              },
              error => {
                console.log(error);
                this.errorMessage = 'Something went wrong with your request, please try again.';
              }
          );
      }
  }

  getUserData(userid: string){
    //on fresh login
    if(userid !== undefined){
      this.userService.getUserDetail(userid).subscribe(res => {
        if(res.data){
          let companies = [];
          
          this.updateUserInfo(
            res.data.mygrow_reportusers[0].id, 
            res.data.mygrow_reportusers[0].full_name,
            res.data.mygrow_reportusers[0].last_name,
            res.data.mygrow_reportusers[0].level,
            res.data.mygrow_reportusers[0].email,
            companies
          );

          this.getUserCompanies();
        }
      });
    }
  }

  getUserCompanies(){
    // get the users companies / company
    this.reportService.getReportHierarchy()
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(res => {
      let userLevels = res.data.userlevel;
      let hierarchy = res.data['Company'];
      let usercompanies = [];
      // retrieve companies
      if(hierarchy){
        Object.keys(hierarchy).forEach(function(key,index) {
          usercompanies.push({
            id: key,
            name: hierarchy[key].Org_Name
          });
        });

        // update hierarchy
        this.store.dispatch(new UpdateCompaniesHierarchy(hierarchy));
      }

      // if the user only has a single company to choose from > select it else populate company selector
      if(usercompanies.length === 1){
        this.store.dispatch(new UpdateSelectedCompany(usercompanies[0].id));
      }else{
        usercompanies.sort(this.dynamicSort("name"));
        this.companies = usercompanies;
      }
      
    });
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
  }

  updateUserInfo(
    id: string,
    first_name: string,
    last_name: string,
    level: string,
    email: string,
    companies: Company[]){
    this.store.dispatch(new UpdateUserInfo({ 
      'id' : id, 
      'first_name' : first_name,
      'last_name' : last_name,
      'level' : level,
      'email' : email,
      'companies' : companies
    }));
  }

  selectCompany(event: number){
    this.store.dispatch(new UpdateSelectedCompany(event));
  }

  logout(){
    this.authService.logout();
    window.location.reload();
  }

}
