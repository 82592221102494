import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { UIModePayload, UIReportLoadingPayload, UIStateModel } from '../interfaces/app.interfaces';

export class UpdateUIMode {
    static readonly type = '[UI] update mode';
    constructor(public payload: UIModePayload) { }
}

export class UpdateReportLoading {
  static readonly type = '[UI] update reportloading';
  constructor(public payload: UIReportLoadingPayload) { }
}

//state
@State<UIStateModel>({
  name: 'reportingui',
  defaults: {
    mode: 'filter',
    reportloading: true 
  }
})
export class UIState {
  constructor() { }

  // Selectors

  @Selector()
  public static uiMode(state: UIStateModel) {
    return state.mode;
  }

  @Selector()
  public static uiReportLoading(state: UIStateModel) {
    return state.reportloading;
  }

  //Actions

  @Action(UpdateUIMode)
  updateUIMode(ctx: StateContext<UIStateModel>, action: UpdateUIMode) {
    ctx.patchState({
      mode: action.payload.mode
    });
  }

  @Action(UpdateReportLoading)
  updateReportLoading(ctx: StateContext<UIStateModel>, action: UpdateReportLoading) {
    ctx.patchState({
      reportloading: action.payload.reportloading
    });
  }

}