import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, UserStateModel } from 'src/app/interfaces/app.interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/report.service';
import { UserService } from 'src/app/services/user.service';
import { ReportState, UpdateCompaniesHierarchy, UpdateReportInfo, UpdateTimeTracking, UpdateImpactReportInfo, UpdateModulesInfo } from 'src/app/state/report.state';
import { UIState } from 'src/app/state/ui.state';
import { UpdateUserInfo, UserState } from 'src/app/state/user.state';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy{
  private ngUnsubscribe = new Subject();
  loading: boolean = true;
  hasData: boolean = false;

  @Select(UIState.uiMode) uiMode$: Observable<string>;
  @Select(UIState.uiReportLoading) uiReportLoading$: Observable<boolean>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<number>;
  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor(
    private router: Router,
    private reportService: ReportService,
    private store: Store
  ) { }

  ngOnInit() {
    this.selectedCompany$
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(selectedCompany => {
      if(selectedCompany === null){
        // user has no company selected so go to select
        this.router.navigateByUrl('/');
      }else{
        // company is selected
        // get intial report data
        this.getReportInit(selectedCompany);
      }
    });

    this.reportInfo$
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(reportdata => {
      // check if we have data
      this.hasData = (reportdata !== null && reportdata.CompanyEQData && reportdata.users) ? true : false;
    });

    this.uiReportLoading$
        .pipe(
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(reportLoading => {
            this.loading = reportLoading;
        })
  }

  getReportInit(selectedCompany: number){
    this.reportService.getReport('org_id='+selectedCompany).subscribe(res => {
      this.store.dispatch(new UpdateReportInfo(res.data.mygrow_reportapi));
    });
    this.reportService.getModulesInfo().subscribe(res => {
      this.store.dispatch(new UpdateModulesInfo(res.data.mygrow_eomapi));
    });
    this.reportService.getImpactReport('org_id='+selectedCompany).subscribe(res => {
      this.store.dispatch(new UpdateImpactReportInfo(res.data.mygrow_reportapi));
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
