import { Component, Inject, NgZone, PLATFORM_ID, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-eq-profile-overview',
  templateUrl: './eq-profile-overview.component.html',
  styleUrls: ['./eq-profile-overview.component.scss']
})
export class EqProfileOverviewComponent implements OnInit {
  private chart: am4charts.XYChart;
  displayChart: boolean = false;
  showPeers: boolean = false;
  footertext: string = '';
  eqprofileoverviewData = [
    {
      area: "Self-Perception",
      bullet: "/assets/images/icons/icon-self.svg",
      total: 10,
      value: 0,
      pvalue: 0
    },
    {
      area: "Self-Expression",
      bullet: "/assets/images/icons/icon-expression.svg",
      total: 10,
      value: 0,
      pvalue: 0
    },
    {
      area: "Interpersonal",
      bullet: "/assets/images/icons/icon-interpersonal.svg",
      total: 10,
      value: 0,
      pvalue: 0
    },
    {
      area: "Decision Making",
      bullet: "/assets/images/icons/icon-decisions.svg",
      total: 10,
      value: 0,
      pvalue: 0
    },
    {
      area: "Stress Management",
      bullet: "/assets/images/icons/icon-stress.svg",
      total: 10,
      value: 0,
      pvalue: 0
    }
  ];

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportdata => {
      if(reportdata !== null && reportdata.CompanyEQData){
        // if SelfandPeer
        if(
          reportdata.CompanyEQData.SelfAndPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] >= 5
        ){
            this.showPeers = true;
            // if over 5
            this.footertext = '*Based on '+reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1]+' Self-Assessments with '+reportdata.CompanyEQData.TotalPeers[1]+' Peer-Assessments.';
            // TODO: use first and second rounds? check for first round?
            // get self data
            // self perception
            this.eqprofileoverviewData[0].value = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Self Regard']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Self Actualisation']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Emotional Self-Awareness']) / 3
              );

            // self expression
            this.eqprofileoverviewData[1].value = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Emotional Expression']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Assertiveness']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Independence']) / 3
              );

            //  interpersonal
            this.eqprofileoverviewData[2].value = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Interpersonal Relationships']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Empathy']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Social Responsibility']) / 3
            );

            // decision making
            this.eqprofileoverviewData[3].value = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Problem Solving']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Reality Testing']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Impulse Control']) / 3
            );

            // stress management
            this.eqprofileoverviewData[4].value = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Flexibility']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Stress Tolerance']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Self']['Optimism']) / 3
            );

            // TODO: get peer data
            // self perception
            this.eqprofileoverviewData[0].pvalue = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Self Regard']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Self Actualisation']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Emotional Self-Awareness']) / 3
              );

            // self expression
            this.eqprofileoverviewData[1].pvalue = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Emotional Expression']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Assertiveness']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Independence']) / 3
              );

            //  interpersonal
            this.eqprofileoverviewData[2].pvalue = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Interpersonal Relationships']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Empathy']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Social Responsibility']) / 3
            );

            // decision making
            this.eqprofileoverviewData[3].pvalue = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Problem Solving']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Reality Testing']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Impulse Control']) / 3
            );

            // stress management
            this.eqprofileoverviewData[4].pvalue = this.roundMe(
              (reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Flexibility']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Stress Tolerance']
              + reportdata.CompanyEQData.SelfAndPeer[1]['Peer']['Optimism']) / 3
            );
            
              // render graph
              this.displayChart = true;
              this.initChart("eqprofileoverview",this.eqprofileoverviewData, true);

        }else if(
          (reportdata.CompanyEQData.SelfOnly && 
          reportdata.CompanyEQData.TotalSelfOnly && 
          !reportdata.CompanyEQData.SelfAndPeer && 
          !reportdata.CompanyEQData.TotalSelfAssessmentswithPeer &&
          reportdata.CompanyEQData.TotalSelfOnly[1] >= 5) 
          ||
          (reportdata.CompanyEQData.SelfOnly && 
            reportdata.CompanyEQData.TotalSelfOnly && 
            reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
            reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] < 5 &&
            reportdata.CompanyEQData.TotalSelfOnly[1] >= 5)
        ){
          // if SelfOnly
          // if 5 or over
            this.footertext = '*Based on '+reportdata.CompanyEQData.TotalSelfOnly[1]+' Self-Assessments.';
            // get self data
            // self perception
            this.eqprofileoverviewData[0].value = this.roundMe(
              (reportdata.CompanyEQData.SelfOnly[1]['Self Regard']
              + reportdata.CompanyEQData.SelfOnly[1]['Self Actualisation']
              + reportdata.CompanyEQData.SelfOnly[1]['Emotional Self-Awareness']) / 3
              );

            // self expression
            this.eqprofileoverviewData[1].value = this.roundMe(
              (reportdata.CompanyEQData.SelfOnly[1]['Emotional Expression']
              + reportdata.CompanyEQData.SelfOnly[1]['Assertiveness']
              + reportdata.CompanyEQData.SelfOnly[1]['Independence']) / 3
              );

            //  interpersonal
            this.eqprofileoverviewData[2].value = this.roundMe(
              (reportdata.CompanyEQData.SelfOnly[1]['Interpersonal Relationships']
              + reportdata.CompanyEQData.SelfOnly[1]['Empathy']
              + reportdata.CompanyEQData.SelfOnly[1]['Social Responsibility']) / 3
            );

            // decision making
            this.eqprofileoverviewData[3].value = this.roundMe(
              (reportdata.CompanyEQData.SelfOnly[1]['Problem Solving']
              + reportdata.CompanyEQData.SelfOnly[1]['Reality Testing']
              + reportdata.CompanyEQData.SelfOnly[1]['Impulse Control']) / 3
            );

            // stress management
            this.eqprofileoverviewData[4].value = this.roundMe(
              (reportdata.CompanyEQData.SelfOnly[1]['Flexibility']
              + reportdata.CompanyEQData.SelfOnly[1]['Stress Tolerance']
              + reportdata.CompanyEQData.SelfOnly[1]['Optimism']) / 3
            );

              // render graph
              this.displayChart = true;
              this.initChart("eqprofileoverview",this.eqprofileoverviewData);

        }else{
          this.footertext = 'Not enough data to render the graph.';
          this.displayChart = false;
        }

      }
      
    });
  }

  roundMe(number: number){
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  initChart(id:string, data: any, peers: boolean = false){
    // Chart code goes in here
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      am4core.options.autoSetClassName = true;

      let chart = am4core.create(id, am4charts.XYChart);
      // Add data
      chart.data = data;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "area";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      //categoryAxis.renderer.grid.disabled = true;
      
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 10;
      
      // Create series for outline bars
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "total";
      series.dataFields.categoryX = "area";
      series.clustered = false;
      series.fill = am4core.color("#ffffff");
      series.columns.template.width = am4core.percent(60);
      series.stroke = am4core.color("#C4C4C4");
      series.strokeWidth = 1;

      // Create series for Growth Area bars
      var series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "value";
      series2.dataFields.categoryX = "area";
      series2.columns.template.fillOpacity = 1;
      series2.tooltip.disabled = true;
      series2.columns.template.width = am4core.percent(60);

      if(peers){
        // Create series for peer reviews
        var series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = "pvalue";
        series3.dataFields.categoryX = "area";
        series3.tooltip.disabled = true;
        series3.fill = am4core.color("#FF0000");
        series3.stroke = am4core.color("#FF0000");

        //add bullets
        var circleBullet = series3.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = am4core.color("#fff");
        circleBullet.circle.strokeWidth = 2;
      }
      
      // Add bullets
      var bullet = series.bullets.push(new am4charts.Bullet());
      var image = bullet.createChild(am4core.Image);
      image.horizontalCenter = "middle";
      image.verticalCenter = "bottom";
      image.dy = -10;
      image.y = am4core.percent(100);
      image.height = 30;
      image.propertyFields.href = "bullet";
      image.tooltipText = series.columns.template.tooltipText;
      image.propertyFields.fill = "color";

      chart.maskBullets = false;
      chart.paddingTop = 40;
      chart.paddingLeft = 0;
      chart.paddingBottom = 0;

      //border radius
      series.columns.template.column.cornerRadiusTopLeft = 8;
      series.columns.template.column.cornerRadiusTopRight = 8;
      series.columns.template.column.cornerRadiusBottomLeft = 8;
      series.columns.template.column.cornerRadiusBottomRight = 8;
      series2.columns.template.column.cornerRadiusTopLeft = 8;
      series2.columns.template.column.cornerRadiusTopRight = 8;
      series2.columns.template.column.cornerRadiusBottomLeft = 8;
      series2.columns.template.column.cornerRadiusBottomRight = 8;
      
      var columnTemplate = series2.columns.template;
      columnTemplate.strokeWidth = 0;
      columnTemplate.strokeOpacity = 0;

      this.chart = chart;
    });
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
