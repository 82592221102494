import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-eq-profile-top-strengths',
  templateUrl: './eq-profile-top-strengths.component.html',
  styleUrls: ['./eq-profile-top-strengths.component.scss']
})
export class EqProfileTopStrengthsComponent implements OnInit {
  displayChart: boolean = false;
  sectionColors = {
    'Self Regard':'#f69497',
    'Self Actualisation':'#f69497',
    'Emotional Self-Awareness':'#f69497',
    'Emotional Expression':'#fec19b',
    'Assertiveness':'#fec19b',
    'Independence':'#fec19b',
    'Interpersonal Relationships':'#fde79f',
    'Empathy':'#fde79f',
    'Social Responsibility':'#fde79f',
    'Problem Solving':'#98f3e1',
    'Reality Testing':'#98f3e1',
    'Impulse Control':'#98f3e1',
    'Flexibility':'#9ACFF7',
    'Stress Tolerance':'#9ACFF7',
    'Optimism':'#9ACFF7',
  }
  topStrengths: any = [];
  topGrowthAreas: any = [];
  topBlindSpots: any = [];

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() { }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportdata => {
      this.topStrengths = [];
      this.topGrowthAreas = [];
      this.topBlindSpots = [];

      if(reportdata !== null && reportdata.CompanyEQData){
        // if SelfandPeer
        if(
          reportdata.CompanyEQData.SelfAndPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer &&
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] >= 5
        ){
          // if 5 or more
          // get strengths
          if(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths']){
            let keys = Object.keys(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths']);
            let totalValue = 0;
            keys.forEach(element => {
                let title = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths'][element].Title;
                let value = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths'][element].Value;
                totalValue = totalValue + parseFloat(value.toFixed(2));
                this.topStrengths.push({
                  title: title,
                  color: this.sectionColors[title],
                  width: value.toFixed(2)
                })
            });
            // work out widths
            this.topStrengths = this.topStrengths.map(item => {
              let newvalue = (item.width / parseFloat(totalValue.toFixed(2))) * 100;
              return {
                title: item.title,
                color: item.color,
                width: newvalue.toFixed(2)+'%'
              }
            });
          }

          // get growth areas
          if(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses']){
            let keys = Object.keys(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses']);
            let totalValue = 0;
            keys.forEach(element => {
                let title = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses'][element].Title;
                let value = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses'][element].Value;
                totalValue = totalValue + parseFloat(value.toFixed(2));
                this.topGrowthAreas.push({
                  title: title,
                  color: this.sectionColors[title],
                  width: value.toFixed(2)
                })
            });
            // work out widths
            this.topGrowthAreas = this.topGrowthAreas.map(item => {
              let newvalue = (item.width / parseFloat(totalValue.toFixed(2))) * 100;
              return {
                title: item.title,
                color: item.color,
                width: newvalue.toFixed(2)+'%'
              }
            });
          }

          // get blind spots
          if(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots']){
            let keys = Object.keys(reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots']);
            let totalValue = 0;
            keys.forEach(element => {
                let title = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots'][element].Title;
                let value = reportdata.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots'][element].Value;
                totalValue = totalValue + parseFloat(value.toFixed(2));
                this.topBlindSpots.push({
                  title: title,
                  color: this.sectionColors[title],
                  width: value.toFixed(2)
                })
            });
            // work out widths
            this.topBlindSpots = this.topBlindSpots.map(item => {
              let newvalue = (item.width / parseFloat(totalValue.toFixed(2))) * 100;
              return {
                title: item.title,
                color: item.color,
                width: newvalue.toFixed(2)+'%'
              }
            });
          }
            
          // render graph
          this.displayChart = true;
        }else if(
          (reportdata.CompanyEQData.SelfOnly && 
            reportdata.CompanyEQData.TotalSelfOnly && 
            !reportdata.CompanyEQData.SelfAndPeer && 
            !reportdata.CompanyEQData.TotalSelfAssessmentswithPeer &&
            reportdata.CompanyEQData.TotalSelfOnly[1] >= 5) 
            ||
            (reportdata.CompanyEQData.SelfOnly && 
              reportdata.CompanyEQData.TotalSelfOnly && 
              reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
              reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] < 5 &&
              reportdata.CompanyEQData.TotalSelfOnly[1] >= 5)
        ){
          // if SelfOnly
          // if 5 or more
          
          // get strengths
          if(reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Strengths']){
            let keys = Object.keys(reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Strengths']);
            let totalValue = 0;
            keys.forEach(element => {
                let title = reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Strengths'][element].Title;
                let value = reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Strengths'][element].Value;
                totalValue = totalValue + parseFloat(value.toFixed(2));
                this.topStrengths.push({
                  title: title,
                  color: this.sectionColors[title],
                  width: value.toFixed(2)
                })
            });
            // work out widths
            this.topStrengths = this.topStrengths.map(item => {
              let newvalue = (item.width / parseFloat(totalValue.toFixed(2))) * 100;
              return {
                title: item.title,
                color: item.color,
                width: newvalue.toFixed(2)+'%'
              }
            });
          }

          // get growth areas
          if(reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Weaknesses']){
            let keys = Object.keys(reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Weaknesses']);
            let totalValue = 0;
            keys.forEach(element => {
                let title = reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Weaknesses'][element].Title;
                let value = reportdata.CompanyEQData.SelfOnly[1]['SelfOnly-Weaknesses'][element].Value;
                totalValue = totalValue + parseFloat(value.toFixed(2));
                this.topGrowthAreas.push({
                  title: title,
                  color: this.sectionColors[title],
                  width: value.toFixed(2)
                })
            });
            // work out widths
            this.topGrowthAreas = this.topGrowthAreas.map(item => {
              let newvalue = (item.width / parseFloat(totalValue.toFixed(2))) * 100;
              return {
                title: item.title,
                color: item.color,
                width: newvalue.toFixed(2)+'%'
              }
            });
          }

          // render graph
          this.displayChart = true;

        }else{
          this.displayChart = false;
        }
      }
      
    });
  }

}
