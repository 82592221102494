import { Component, Inject, NgZone, PLATFORM_ID, OnInit, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-engagement-overview',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.scss']
})
export class EngagementOverviewComponent implements OnInit, OnDestroy {
  private chart: am4charts.XYChart;
  doubleGraph: boolean = false;
  
  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit() {
    this.reportInfo$.subscribe(res => {
      if(res !== undefined && res !== null && res.averageEngagement && typeof res.averageEngagement === 'object' && !Array.isArray(res.averageEngagement) && res.averageEngagement !== null){
        this.initGraph(res.averageEngagement);
      }

      if(res !== null && res.averageEngagement && res.comanyAverageEngagement){
          if(
          typeof res.averageEngagement === 'object' && 
          !Array.isArray(res.averageGrowthScore) && 
          res.averageEngagement !== null &&
          typeof res.comanyAverageEngagement === 'object' && 
          !Array.isArray(res.comanyAverageEngagement) && 
          res.comanyAverageEngagement !== null
          ){
            this.initGraph(res.averageEngagement, res.comanyAverageEngagement);
            this.doubleGraph = true;
          }
      }else if(res !== null && res.averageEngagement){
        if(
        typeof res.averageEngagement === 'object' && 
        !Array.isArray(res.averageEngagement) && 
        res.averageEngagement !== null
        ){
          this.initGraph(res.averageEngagement);
      }
    }
    });
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  returnDate(datestr: string){
    let date = new Date(datestr);
    date.setHours(0,0,0,0);
    return date;
  }

  initGraph(gsobj: Object, cgsobj: Object = null){
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      am4core.options.autoSetClassName = true;

      let chart = am4core.create("engagementchartdiv", am4charts.XYChart);
      chart.paddingRight = 30;
      chart.height = 300;
      let items = [];

      if(cgsobj !== null){
        items = Object.keys(gsobj).map((key) => (
          {
            date:this.returnDate(key), 
            value:gsobj[key],
            value2:+cgsobj[key]
          }
        ));
      }else{
        items = Object.keys(gsobj).map((key) => (
          {
            date:this.returnDate(key), 
            value:gsobj[key]
          }
        ));
      }

      chart.data = items;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // if(items.length < 30){
      //   dateAxis.gridIntervals.setAll([
      //     { timeUnit: "day", count: 1 }
      //   ]);
      // }else if(items.length >= 30){
      //   // dateAxis.gridIntervals.setAll([
      //   //   { timeUnit: "day", count: 3 }
      //   // ]);
      //   dateAxis.gridIntervals.setAll([
      //     { timeUnit: "week", count: 1 }
      //   ]);
      // }

      dateAxis.renderer.minGridDistance = 40;
      
      dateAxis.renderer.grid.template.location = 0.5;
      dateAxis.startLocation = 0.5;
      dateAxis.endLocation = 0.5;
      dateAxis.cursorTooltipEnabled = false;
      dateAxis.dateFormats.setKey("day", "dd MMM yy");

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 7;
      valueAxis.cursorTooltipEnabled = false;

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value";
      series.dataFields.dateX = "date";
      series.stroke = am4core.color("#3EA9F5");

      // Drop-shaped tooltips
      series.tooltipText = "{value}";
      series.tooltip.background.cornerRadius = 20;
      series.tooltip.background.strokeOpacity = 0;
      series.tooltip.pointerOrientation = "vertical";
      series.tooltip.label.minWidth = 30;
      series.tooltip.label.minHeight = 20;
      series.tooltip.label.textAlign = "middle";
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#3EA9F5");
      
      // Make bullets grow on hover
      series.minBulletDistance = 15;
      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color("#fff");

      var bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.3;

      if(cgsobj !== null){
        // Create series 2 for Company data
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "value2";
        series2.dataFields.dateX = "date";
        series2.stroke = am4core.color("#404040");

        // Drop-shaped tooltips
        series2.tooltipText = "{value2}";
        series2.tooltip.background.cornerRadius = 20;
        series2.tooltip.background.strokeOpacity = 0;
        series2.tooltip.pointerOrientation = "vertical";
        series2.tooltip.label.minWidth = 30;
        series2.tooltip.label.minHeight = 20;
        series2.tooltip.label.textAlign = "middle";
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = am4core.color("#404040");

        // Make bullets grow on hover
        series2.minBulletDistance = 15;
        var bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.strokeWidth = 2;
        bullet2.circle.radius = 4;
        bullet2.circle.fill = am4core.color("#fff");

        // Make a panning cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.showTooltipOn = "hover";
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = [series,series2];
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
      }else{
        // Make a panning cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.showTooltipOn = "hover";
        chart.cursor.behavior = "panXY";
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
      }

      chart.paddingTop = 10;
      chart.paddingLeft = 0;
      chart.paddingBottom = 0;
      chart.numberFormatter.numberFormat = "#.##";

      this.chart = chart;
    });
  }
}