import { Component, OnDestroy, OnInit } from '@angular/core';
import zipcelx from 'zipcelx';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable'
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { UserState } from 'src/app/state/user.state';
import { UserStateModel } from 'src/app/interfaces/app.interfaces';
import { takeUntil } from 'rxjs/operators';
import { ReportState } from 'src/app/state/report.state';
import { NavState, UpdateTimeFilter } from 'src/app/state/nav.state';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  downloadDropdownOpen: boolean = false;
  name: string = '';
  users = [];
  companyName: string = '';
  companyLogo: string = '';
  companyAverageGS = 0;
  companyDropletsPW = 0;
  usersAtLevels = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14:0
  };
  averageWeeklyEngagementBreakdown = {
    'low': 0,
    'below_average': 0,
    'above_average': 0,
    'highly_engaged': 0
  };
  topStrengths: any = [];
  topGrowthAreas: any = [];
  topBlindSpots: any = [];
  selectedTimeFrame: any = 0;
  timeFrames = [
      { id: 0, name: 'Last 7 Days' },
      { id: 1, name: 'Last 14 Days' },
      { id: 2, name: 'Last 30 Days' },
      // { id: 3, name: 'Last 3 Months' },
      // { id: 4, name: 'Last 6 Months' },
      // { id: 5, name: 'Last 1 Year' }
  ];

  @Select(UserState.user) user$: Observable<UserStateModel>;
  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;
  @Select(ReportState.companiesHierarchy) companiesHierarchy$: Observable<any>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<number>;

  constructor(private store: Store) { }

  ngOnInit() {
    // load user name
    this.user$.subscribe(res => {
      if(res.first_name){
        this.name = res.first_name;
      }
    });
    // get info for reports
    combineLatest(this.companiesHierarchy$, this.selectedCompany$, this.reportInfo$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(
      ([companiesHierarchy, selectedCompany, reportInfo]) => {
        if(companiesHierarchy !== null && companiesHierarchy[selectedCompany].Org_Name){
          this.companyName = companiesHierarchy[selectedCompany].Org_Name;
          this.companyLogo = companiesHierarchy[selectedCompany].Logo;
        }

        if(
          reportInfo !== null 
          && reportInfo.users !== undefined
        ){
          
          this.companyAverageGS = this.getLastObjectItem(reportInfo.averageGrowthScore).toFixed(2);
          this.companyDropletsPW = this.getLastObjectItem(reportInfo.averageEngagement).toFixed(2);
          //reset below
          this.usersAtLevels = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14:0
          };
          this.averageWeeklyEngagementBreakdown = {
            'low': 0,
            'below_average': 0,
            'above_average': 0,
            'highly_engaged': 0
          };

          const $this = this;
          this.users = Object.keys(reportInfo.users).map(function(key, index) {
            let level = reportInfo.users[key].level;
            $this.usersAtLevels[+level] = $this.usersAtLevels[+level] + 1;
            if(reportInfo.users[key].engagement < 2){
              $this.averageWeeklyEngagementBreakdown['low'] = $this.averageWeeklyEngagementBreakdown['low'] + 1;
            }else if(reportInfo.users[key].engagement >= 2 && reportInfo.users[key].engagement < 2.5){
              $this.averageWeeklyEngagementBreakdown['below_average'] = $this.averageWeeklyEngagementBreakdown['below_average'] + 1;
            }else if(reportInfo.users[key].engagement >= 2.5 && reportInfo.users[key].engagement < 4){
              $this.averageWeeklyEngagementBreakdown['above_average'] = $this.averageWeeklyEngagementBreakdown['above_average'] + 1;
            }else if(reportInfo.users[key].engagement >= 4){
              $this.averageWeeklyEngagementBreakdown['highly_engaged'] = $this.averageWeeklyEngagementBreakdown['highly_engaged'] + 1;
            }
            let perc = reportInfo.users[key].modulepercentcomplete;
            let levels = [
              {
                level: 1,
                name: 'bootcamp',
                perc: 0
              },
              {
                level: 2,
                name: 'self_awareness',
                perc: 0
              },
              {
                level: 3,
                name: 'self_perception',
                perc: 0
              },
              {
                level: 4,
                name: 'self_expression',
                perc: 0
              },
              {
                level: 5,
                name: 'interpersonal',
                perc: 0
              },
              {
                level: 6,
                name: 'decision_making',
                perc: 0
              },
              {
                level: 7,
                name: 'stress_management',
                perc: 0
              },
              {
                level: 8,
                name: 'self_awareness2',
                perc: 0
              },
              {
                level: 9,
                name: 'self_regard',
                perc: 0
              },
              {
                level: 10,
                name: 'emotional_language',
                perc: 0
              },
              {
                level: 11,
                name: 'assertiveness',
                perc: 0
              },
              {
                level: 12,
                name: 'interpersonal_relationships',
                perc: 0
              },
              {
                level: 13,
                name: 'problem_solving',
                perc: 0
              },
              {
                level: 14,
                name: 'flexibility',
                perc: 0
              }
            ];
            for (let item of levels) {
              if(item.level === parseInt(level) && parseInt(level) > 0){
                item.perc = Math.trunc(parseInt(perc));
                break;
              }else if(parseInt(level) === 0){
                break;
              }else{
                item.perc = 100;
              }
            }
            let variance = 0;
            if($this.companyAverageGS){
              variance = ((reportInfo.users[key].growthscore - $this.companyAverageGS) / $this.companyAverageGS) * 100;
              variance = Math.round((variance + Number.EPSILON) * 100) / 100;
            }
            return {
              fname: reportInfo.users[key].firstname,
              lname: reportInfo.users[key].lastname,
              company: $this.companyName,
              country: reportInfo.users[key].country,
              region: reportInfo.users[key].region,
              division: reportInfo.users[key].division,
              department: reportInfo.users[key].department,
              team: reportInfo.users[key].team,
              line_manager: '',//TODO
              job_title: reportInfo.users[key].role,
              email: reportInfo.users[key].email,
              growth_score: reportInfo.users[key].growthscore,
              variance: variance,
              date_of_first_droplet: reportInfo.users[key].dropletstartdate,
              date_enrolled: reportInfo.users[key].regdate,
              months_since_enrolled: reportInfo.users[key].monthsonmygrow,
              total_progress: reportInfo.users[key].dropletsCompleted,
              level: reportInfo.users[key].level,
              droplets_per_workweek: reportInfo.users[key].engagement,
              work_days_since_last_login: reportInfo.users[key].workdayssincelogin,
              eq_assessment1_completed: (reportInfo.users[key].eq_assessment !== undefined && reportInfo.users[key].eq_assessment[1] !== undefined && reportInfo.users[key].eq_assessment[1].eq_assessment_completed) ? reportInfo.users[key].eq_assessment[1].eq_assessment_completed : 'No',
              eq_assessment1_qualifying: (reportInfo.users[key].eq_assessment !== undefined && reportInfo.users[key].eq_assessment[1] !== undefined && reportInfo.users[key].eq_assessment[1].qualifying_assessment) ? reportInfo.users[key].eq_assessment[1].qualifying_assessment : 'No',
              eq_assessment2_completed: (reportInfo.users[key].eq_assessment !== undefined && reportInfo.users[key].eq_assessment[2] !== undefined && reportInfo.users[key].eq_assessment[2].eq_assessment_completed) ? reportInfo.users[key].eq_assessment[2].eq_assessment_completed : 'No',
              eq_assessment2_qualifying: (reportInfo.users[key].eq_assessment !== undefined && reportInfo.users[key].eq_assessment[2] !== undefined && reportInfo.users[key].eq_assessment[2].qualifying_assessment) ? reportInfo.users[key].eq_assessment[2].qualifying_assessment : 'No',
              bootcamp: levels[0].perc.toString(),
              self_awareness: levels[1].perc.toString(),
              self_perception: levels[2].perc.toString(),
              self_expression: levels[3].perc.toString(),
              interpersonal: levels[4].perc.toString(),
              decision_making: levels[5].perc.toString(),
              stress_management: levels[6].perc.toString(),
              self_awareness2: levels[7].perc.toString(),
              self_regard: levels[8].perc.toString(),
              emotional_language: levels[9].perc.toString(),
              assertiveness: levels[10].perc.toString(),
              interpersonal_relationships: levels[11].perc.toString(),
              problem_solving: levels[12].perc.toString(),
              flexibility: levels[13].perc.toString()
            }
          });

          // sort by progress
          this.users.sort(function(a, b) {
            return b.total_progress - a.total_progress;
          });

          //get top strengths, growth areas, blind spots and 
          if(reportInfo !== null && reportInfo.CompanyEQData){
            // if SelfandPeer
            if(reportInfo.CompanyEQData.SelfAndPeer && reportInfo.CompanyEQData.TotalSelfAssessmentswithPeer){
              // if over 5
              if(reportInfo.CompanyEQData.TotalSelfAssessmentswithPeer[1] > 5){
                // get strengths
                if(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths']){
                  let keys = Object.keys(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths']);
                  let totalValue = 0;
                  keys.forEach(element => {
                      let title = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths'][element].Title;
                      let value = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Strengths'][element].Value;
                      totalValue = totalValue + parseFloat(value.toFixed(2));
                      this.topStrengths.push({
                        title: title
                      })
                  });
                }
    
                // get growth areas
                if(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses']){
                  let keys = Object.keys(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses']);
                  let totalValue = 0;
                  keys.forEach(element => {
                      let title = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses'][element].Title;
                      let value = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Weaknesses'][element].Value;
                      totalValue = totalValue + parseFloat(value.toFixed(2));
                      this.topGrowthAreas.push({
                        title: title
                      })
                  });
                }
    
                // get blind spots
                if(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots']){
                  let keys = Object.keys(reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots']);
                  let totalValue = 0;
                  keys.forEach(element => {
                      let title = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots'][element].Title;
                      let value = reportInfo.CompanyEQData.SelfAndPeer[1]['SelfAndPeer-Blindspots'][element].Value;
                      totalValue = totalValue + parseFloat(value.toFixed(2));
                      this.topBlindSpots.push({
                        title: title
                      })
                  });
                }
                
              }
            }
          }
        }
      }
    );

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getLastObjectItem(obj: any){
    if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null){
      return obj[Object.keys(obj)[Object.keys(obj).length - 1]];
    }else{
      return obj;
    }
  }

  downloadSpreadsheet(){
    let data = [
      [{
        value: 'Company',
        type: 'string'
        },{
        value: 'Country',
        type: 'string'
        },{
        value: 'Region',
        type: 'string'
        },{
        value: 'Division',
        type: 'string'
        },{
        value: 'Department',
        type: 'string'
        },{
        value: 'Team',
        type: 'string'
        },{
        value: 'Line Manager',
        type: 'string'
        },{
        value: 'Job Title',
        type: 'string'
        },{
        value: 'First Name',
        type: 'string'
        },{
        value: 'Surname',
        type: 'string'
        },{
        value: 'Email',
        type: 'string'
        },{
        value: 'Growth Score',
        type: 'string'
        },{
        value: 'Variance %',
        type: 'string'
        },{
        value: 'Date of First Droplet',
        type: 'string'
        },{
        value: 'Date Enrolled',
        type: 'string'
        },{
        value: 'Months since enrolled',
        type: 'string'
        },{
        value: 'Total Progress',
        type: 'string'
        },{
        value: 'Level',
        type: 'string'
        },{
        value: 'Droplets per workweek',
        type: 'string'
        },{
        value: 'Work days since last login',
        type: 'string'
        },{
        value: 'EQ Assessment 1 Completed',
        type: 'string'
        },{
        value: 'EQ Assessment 1 Qualifying',
        type: 'string'
        },{
        value: 'Bootcamp',
        type: 'string'
        },{
        value: 'Self-Awareness',
        type: 'string'
        },{
        value: 'Self-Perception',
        type: 'string'
        },{
        value: 'Self-Expression',
        type: 'string'
        },{
        value: 'Interpersonal',
        type: 'string'
        },{
        value: 'Decision Making',
        type: 'string'
        },{
        value: 'Stress Management',
        type: 'string'
        },{
        value: 'Self Awareness 2',
        type: 'string'
        },{
        value: 'Self-Regard 2',
        type: 'string'
        },{
        value: 'Emotional Language',
        type: 'string'
        },{
        value: 'Assertiveness',
        type: 'string'
        },{
        value: 'Relationships',
        type: 'string'
        },{
        value: 'Problem Solving',
        type: 'string'
        },{
        value: 'Flexibility',
        type: 'string'
        }]
    ];

    // add user data
    if(this.users.length > 0){
      this.users.forEach(user => {
        data.push([{
          value: user.company,
          type: 'string'
          },{
          value: user.country,
          type: 'string'
          },{
          value: user.region,
          type: 'string'
          },{
          value: user.division,
          type: 'string'
          },{
          value: user.department,
          type: 'string'
          },{
          value: user.team,
          type: 'string'
          },{
          value: user.line_manager,
          type: 'string'
          },{
          value: user.job_title,
          type: 'string'
          },{
          value: user.fname,
          type: 'string'
          },{
          value: user.lname,
          type: 'string'
          },{
          value: user.email,
          type: 'string'
          },{
          value: user.growth_score,
          type: 'string'
          },{
          value: user.variance,
          type: 'string'
          },{
          value: user.date_of_first_droplet,
          type: 'string'
          },{
          value: user.date_enrolled,
          type: 'string'
          },{
          value: user.months_since_enrolled,
          type: 'string'
          },{
          value: user.total_progress,
          type: 'string'
          },{
          value: user.level,
          type: 'string'
          },{
          value: user.droplets_per_workweek,
          type: 'string'
          },{
          value: user.work_days_since_last_login,
          type: 'string'
          },{
          value: user.eq_assessment1_completed,
          type: 'string'
          },{
          value: user.eq_assessment1_qualifying,
          type: 'string'
          },{
          value: user.bootcamp,
          type: 'string'
          },{
          value: user.self_awareness,
          type: 'string'
          },{
          value: user.self_perception,
          type: 'string'
          },{
          value: user.self_expression,
          type: 'string'
          },{
          value: user.interpersonal,
          type: 'string'
          },{
          value: user.decision_making,
          type: 'string'
          },{
          value: user.stress_management,
          type: 'string'
          },{
          value: user.self_awareness2,
          type: 'string'
          },{
          value: user.self_regard,
          type: 'string'
          },{
          value: user.emotional_language,
          type: 'string'
          },{
          value: user.assertiveness,
          type: 'string'
          },{
          value: user.interpersonal_relationships,
          type: 'string'
          },{
          value: user.problem_solving,
          type: 'string'
          },{
          value: user.flexibility,
          type: 'string'
          }]);
      });
    }

    const config = {
      filename: 'Mygrow-Progress-Report',
      sheet: {
        data: data
      }
    };
    
    zipcelx(config);
  }

  // getBase64Image() {
  //   const $this = this;
  //   let img = new Image();
  //   let dataURL;
  //   let returnData = []

  //   img.src = this.companyLogo;
  //   img.crossOrigin="anonymous";
   
  //   let percentOfOriginalLogoHeight = 0;
  //   let logow = 0;
  //   let logoXpos = 0;

  //   img.onload = function() {

  //       percentOfOriginalLogoHeight = ( (20 / 0.26458333) / img.height) * 100;
  //       logow = Math.round( (( img.width / 100) * percentOfOriginalLogoHeight) * 0.26458333);
  //       logoXpos = 210 - (logow + 10);
      
  //       var canvas = document.createElement('canvas');
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       var context = canvas.getContext('2d');
  //       context.drawImage(img, 0, 0);

  //       dataURL = canvas.toDataURL('image/jpeg');

  //       returnData['dataURL'] = dataURL;
  //       returnData['logow'] = logow;
  //       returnData['logoXpos'] = logoXpos;

  //       console.log(returnData);

  //       $this.downloadPDF3(returnData);
  //   }
  // }

  downloadPDF3(){
    if(this.companyName != '' && this.companyLogo != '' && this.users.length > 0){
      //default unit is mm (0.26458333 millimeters in a pixel)
      const doc = new jsPDF();// jsPDF(orientation, unit, format)
      let mygrowlogo = new Image();
      mygrowlogo.src = 'assets/images/logo.png';

      //let companylogo = new Image();
      // companylogo.crossOrigin = "Anonymous";
      // companylogo.src = this.companyLogo;
      // console.log(companylogo);
      
      // let percentOfOriginalLogoHeight = ( (20 / 0.26458333) / companylogo.height) * 100;
      // let logow = Math.round( (( companylogo.width / 100) * percentOfOriginalLogoHeight) * 0.26458333);
      // let logoXpos = 210 - (logow + 10);

      doc.addImage(mygrowlogo, 'png', 10, 10, 80, 20);
      //doc.addImage(companylogo['dataURL'], 'png', companylogo['logoXpos'], 10, companylogo['logow'], 20);
      doc.text("Progress Report for "+this.companyName, 10, 40);
      doc.text("Company Overview", 10, 50);
      //autoTable(doc, { html: '#pdfContentTable' })
      // Exec Summary
      autoTable(doc, {
        columnStyles: {
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 63.3
          } 
        },
        headStyles: {
          fillColor: [62, 169, 245],
          cellWidth: 63.3
        },
        body: [
          { label: 'Company Name', content: this.companyName },
          { label: 'Enrolled Users', content: this.users.length },
          { label: 'Average Growthscore', content: this.companyAverageGS },
          { label: 'Average Droplets per week *', content: this.companyDropletsPW },
        ],
        columns: [
          { header: 'Executive Summary', dataKey: 'label' },
          { header: '', dataKey: 'content' },
        ],
        startY: 60,
        margin: 10
      })
      // Fundementals 101
      autoTable(doc, {
        columnStyles: { 
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 63.3
          } 
        },
        headStyles: {
          fillColor: [62, 169, 245],
          cellWidth: 63.3
        },
        body: [
          { label: 'To Start', content: this.usersAtLevels[0]+' users', perc: ((this.usersAtLevels[0] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '1. Bootcamp', content: this.usersAtLevels[1]+' users', perc: ((this.usersAtLevels[1] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '2. Self-Awareness', content: this.usersAtLevels[2]+' users', perc: ((this.usersAtLevels[2] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '3. Self-Perception', content: this.usersAtLevels[3]+' users', perc: ((this.usersAtLevels[3] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '4. Self-Expression', content: this.usersAtLevels[4]+' users', perc: ((this.usersAtLevels[4] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '5. Interpersonal', content: this.usersAtLevels[5]+' users', perc: ((this.usersAtLevels[5] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '6. Decision Making', content: this.usersAtLevels[6]+' users', perc: ((this.usersAtLevels[6] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '7. Stress Management', content: this.usersAtLevels[7]+' users', perc: ((this.usersAtLevels[7] / this.users.length) * 100).toFixed(2)+'%' }
        ],
        columns: [
          { header: 'Fundamentals 101', dataKey: 'label' },
          { header: '', dataKey: 'content' },
          { header: '', dataKey: 'perc' },
        ],
        margin: 10
      })
      //Fundementals 201
      autoTable(doc, {
        columnStyles: { 
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 63.3
          } 
        },
        headStyles: { 
          fillColor: [62, 169, 245],
          cellWidth: 63.3
        },
        body: [
          { label: '1. Self-Awareness 2 ', content: this.usersAtLevels[8]+' users', perc: ((this.usersAtLevels[8] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '2. Self-Regard', content: this.usersAtLevels[9]+' users', perc: ((this.usersAtLevels[9] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '3. Emotional Language', content: this.usersAtLevels[10]+' users', perc: ((this.usersAtLevels[10] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '4. Assertiveness', content: this.usersAtLevels[11]+' users', perc: ((this.usersAtLevels[11] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '5. Interpersonal Relationships', content: this.usersAtLevels[12]+' users', perc: ((this.usersAtLevels[12] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '6. Problem Solving', content: this.usersAtLevels[13]+' users', perc: ((this.usersAtLevels[13] / this.users.length) * 100).toFixed(2)+'%' },
          { label: '7. Flexibility', content: this.usersAtLevels[14]+' users', perc: ((this.usersAtLevels[14] / this.users.length) * 100).toFixed(2)+'%' }
        ],
        columns: [
          { header: 'Fundamentals 201', dataKey: 'label' },
          { header: '', dataKey: 'content' },
          { header: '', dataKey: 'perc' },
        ],
        margin: 10
      })
      //Average engagement
      autoTable(doc, {
        columnStyles: { 
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 63.3
          }
        },
        headStyles: {
          fillColor: [62, 169, 245],
          cellWidth: 63.3
        },
        body: [
          { label: 'Low(<2)', content: this.averageWeeklyEngagementBreakdown['low']+' users', perc: ((this.averageWeeklyEngagementBreakdown['low'] / this.users.length) * 100).toFixed(2)+'%' },
          { label: 'Below Average(<2.5)', content: this.averageWeeklyEngagementBreakdown['below_average']+' users', perc: ((this.averageWeeklyEngagementBreakdown['below_average'] / this.users.length) * 100).toFixed(2)+'%' },
          { label: 'Above Average(>2.5)', content: this.averageWeeklyEngagementBreakdown['above_average']+' users', perc: ((this.averageWeeklyEngagementBreakdown['above_average'] / this.users.length) * 100).toFixed(2)+'%' },
          { label: 'Highly Engaged(>4)', content: this.averageWeeklyEngagementBreakdown['highly_engaged']+' users', perc: ((this.averageWeeklyEngagementBreakdown['highly_engaged'] / this.users.length) * 100).toFixed(2)+'%' }
        ],
        columns: [
          { header: 'Average Weekly Engagement Breakdown *', dataKey: 'label' },
          { header: '', dataKey: 'content' },
          { header: '', dataKey: 'perc' },
        ],
        margin: 10
      })
      autoTable(doc, {
        headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
        columns: [
          { header: '* This calculation excl. users who haven\'t yet done the first droplet.', dataKey: 'label' }
        ],
        margin: 10
      })
      autoTable(doc, {
        headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
        columns: [
          { header: 'Individual User Overview', dataKey: 'label' }
        ],
        margin: 10
      })
      //Individual Users Fundementals 101
      let individualUsersFund101Body = [];
      this.users.forEach(item => {
        individualUsersFund101Body.push({ 
          fname: item.fname,
          lname: item.lname,
          bootcamp: item.bootcamp+'%',
          selfawareness: item.self_awareness+'%',
          selfperception: item.self_perception+'%',
          selfexpression: item.self_expression+'%',
          interpersonal: item.interpersonal+'%',
          decisionmaking: item.decision_making+'%',
          stressmanagement: item.stress_management+'%'
        });
      });
      autoTable(doc, {
        columnStyles: {
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 21.1
          }
        },
        headStyles: {
          fillColor: [62, 169, 245],
          cellWidth: 21.1
        },
        body: individualUsersFund101Body,
        columns: [
          { header: 'First Name', dataKey: 'fname' },
          { header: 'Last Name', dataKey: 'lname' },
          { header: 'Bootcamp', dataKey: 'bootcamp' },
          { header: 'Self-Awareness ', dataKey: 'selfawareness' },
          { header: 'Self-Perception', dataKey: 'selfperception' },
          { header: 'Self-Expression', dataKey: 'selfexpression' },
          { header: 'Interpersonal', dataKey: 'interpersonal' },
          { header: 'Decision Making', dataKey: 'decisionmaking' },
          { header: 'Stress Management', dataKey: 'stressmanagement' }
        ],
        margin: 10
      })
      //Individual Users Fundementals 201
      let individualUsersFund201 = this.users.filter(item => {
        return item.level >= 8;
      });
      let individualUsersFund201Body = [];
      individualUsersFund201.forEach(item => {
        individualUsersFund201Body.push({ 
          fname: item.fname,
          lname: item.lname,
          selfawareness2: item.self_awareness2+'%',
          selfregard: item.self_regard+'%',
          emotionallanguage: item.emotional_language+'%',
          assertiveness: item.assertiveness+'%',
          interpersonalrelationships: item.interpersonal_relationships+'%',
          problemsolving: item.problem_solving+'%',
          flexibility: item.flexibility+'%'
        });
      });
      autoTable(doc, {
        columnStyles: {
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 21.1
          }
        },
        headStyles: { 
          fillColor: [62, 169, 245],
          cellWidth: 21.1
        },
        body: individualUsersFund201Body,
        columns: [
          { header: 'First Name', dataKey: 'fname' },
          { header: 'Last Name', dataKey: 'lname' },
          { header: 'Self-Awareness 2', dataKey: 'selfawareness2' },
          { header: 'Self-Regard', dataKey: 'selfregard' },
          { header: 'Emotional Language', dataKey: 'emotionallanguage' },
          { header: 'Assertiveness', dataKey: 'assertiveness' },
          { header: 'Interpersonal Relationships', dataKey: 'interpersonalrelationships' },
          { header: 'Problem Solving', dataKey: 'problemsolving' },
          { header: 'Flexibility', dataKey: 'flexibility' }
        ],
        margin: 10
      })
      autoTable(doc, {
        headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
        columns: [
          { header: 'Individual User Detail', dataKey: 'label' }
        ],
        margin: 10
      })
      // Individual Detail
      let individualUsersDetailBody = [];
      this.users.forEach(item => {
        individualUsersDetailBody.push({ 
          name: item.fname+' '+item.lname,
          divisiondepartment: item.division+' - '+item.department,
          countryregion: item.country+' - '+item.region,
          teamjobrole: item.team,
          dateenrolled: item.date_enrolled,
          monthssinceenrolled: item.months_since_enrolled,
          level: item.level,
          totalprogress: item.total_progress,
          dropletsworkweek: item.droplets_per_workweek,
          growthscorevariance: item.growth_score+' ('+item.variance+'%)',
          workdayssincelastlogin: item.work_days_since_last_login
        });
      })
      autoTable(doc, {
        columnStyles: { 
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 17
          } 
        },
        headStyles: { 
          fillColor: [62, 169, 245],
          cellWidth: 17
        },
        body: individualUsersDetailBody,
        columns: [
          { header: 'Name', dataKey: 'name' },
          { header: 'Division - Department', dataKey: 'divisiondepartment' },
          { header: 'Country - Region', dataKey: 'countryregion' },
          { header: 'Team - Job Role', dataKey: 'teamjobrole' },
          { header: 'Date Enrolled', dataKey: 'dateenrolled' },
          { header: 'Months Since Enrolled', dataKey: 'monthssinceenrolled' },
          { header: 'Level', dataKey: 'level' },
          { header: 'Total Progress', dataKey: 'totalprogress' },
          { header: 'Droplets / Workweek', dataKey: 'dropletsworkweek' },
          { header: 'Growth Score (Variance %)', dataKey: 'growthscorevariance' },
          { header: 'Work days since last login', dataKey: 'workdayssincelastlogin' }
        ],
        margin: 10
      })
      autoTable(doc, {
        headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
        columns: [
          { header: 'EQ Assessments - Fundamentals 101', dataKey: 'label' }
        ],
        margin: 10
      })
      // F101 EQ Assessments
      let f101eqAssessmentsBody = [];
      let f101eqAssessmentusers = this.users.filter(user => {
        return user.level < 8;
      });
      f101eqAssessmentusers.forEach(item => {
        f101eqAssessmentsBody.push({
          name: item.fname+' '+item.lname,
          email: item.email,
          dateloaded: item.date_enrolled,
          currentmodule: item.level,
          dateenrolled: item.date_enrolled,
          eqassessmentcompleted: item.eq_assessment1_completed,
          qualifyingassessment: item.eq_assessment1_qualifying
        });
      })
      autoTable(doc, {
        columnStyles: { 
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 27.1
          } 
        },
        headStyles: { 
          fillColor: [62, 169, 245],
          cellWidth: 27.1
        },
        body: f101eqAssessmentsBody,
        columns: [
          { header: 'Name', dataKey: 'name' },
          { header: 'Email', dataKey: 'email' },
          { header: 'Date Loaded', dataKey: 'dateloaded' },
          { header: 'Current Module', dataKey: 'currentmodule' },
          { header: 'Date Enrolled', dataKey: 'dateenrolled' },
          { header: 'EQ Assessment Completed', dataKey: 'eqassessmentcompleted' },
          { header: 'Qualifying Assessment', dataKey: 'qualifyingassessment' }
        ],
        margin: 10
      })
      // autoTable(doc, {
      //   headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
      //   columns: [
      //     { header: 'EQ Assessments - Fundementals 201', dataKey: 'label' }
      //   ],
      //   margin: 10
      // })
      // F201 EQ Assessments
      // let f201eqAssessmentsBody = [];
      // let f201eqAssessmentusers = this.users.filter(user => {
      //   return user.level >= 8;
      // });
      // f201eqAssessmentusers.forEach(item => {
      //   f201eqAssessmentsBody.push({
      //     name: item.fname+' '+item.lname,
      //     email: item.email,
      //     dateloaded: item.date_enrolled,
      //     currentmodule: item.level,
      //     dateenrolled: item.date_enrolled,
      //     eqassessmentcompleted: item.eq_assessment2_completed,
      //     qualifyingassessment: item.eq_assessment2_qualifying
      //   });
      // })
      // autoTable(doc, {
      //   columnStyles: { 
      //     label: {
      //       fillColor: [51, 234, 200],
      //       cellWidth: 27.1
      //     } 
      //   },
      //   headStyles: { 
      //     fillColor: [62, 169, 245],
      //     cellWidth: 27.1
      //   },
      //   body: f201eqAssessmentsBody,
      //   columns: [
      //     { header: 'Name', dataKey: 'name' },
      //     { header: 'Email - Department', dataKey: 'email' },
      //     { header: 'Date Loaded', dataKey: 'dateloaded' },
      //     { header: 'Current Module', dataKey: 'currentmodule' },
      //     { header: 'Date Enrolled', dataKey: 'dateenrolled' },
      //     { header: 'EQ Assessment Completed', dataKey: 'eqassessmentcompleted' },
      //     { header: 'Qualifying Assessment', dataKey: 'qualifyingassessment' }
      //   ],
      //   margin: 10
      // })
      autoTable(doc, {
        headStyles: { fillColor: [255, 255, 255], textColor: [0,0,0], halign: 'center' },
        columns: [
          { header: 'Aggregate Team EQ Assessment', dataKey: 'label' }
        ],
        margin: 10
      })
      // Aggregate Team EQ Assessment
      let aggregateTeamEQBody = [
        {topStrengths: '', topGrowthAreas: '', topBlindSpots: ''},
        {topStrengths: '', topGrowthAreas: '', topBlindSpots: ''},
        {topStrengths: '', topGrowthAreas: '', topBlindSpots: ''}
      ];
      let aggregateTeamEQHeader = [];
      if(this.topStrengths.length > 0){
        aggregateTeamEQBody[0].topStrengths = this.topStrengths[0];
        aggregateTeamEQBody[1].topStrengths = this.topStrengths[1];
        aggregateTeamEQBody[2].topStrengths = this.topStrengths[2];
        aggregateTeamEQHeader.push({ header: 'Top Strengths', dataKey: 'topStrengths' });
      }
      if(this.topGrowthAreas.length > 0){
        aggregateTeamEQBody[0].topGrowthAreas = this.topGrowthAreas[0];
        aggregateTeamEQBody[1].topGrowthAreas = this.topGrowthAreas[1];
        aggregateTeamEQBody[2].topGrowthAreas = this.topGrowthAreas[2];
        aggregateTeamEQHeader.push({ header: 'Top Growth Areas', dataKey: 'topGrowthAreas' });
      }
      if(this.topBlindSpots.length > 0){
        aggregateTeamEQBody[0].topBlindSpots = this.topBlindSpots[0];
        aggregateTeamEQBody[1].topBlindSpots = this.topBlindSpots[1];
        aggregateTeamEQBody[2].topBlindSpots = this.topBlindSpots[2];
        aggregateTeamEQHeader.push({ header: 'Top Blind Spots', dataKey: 'topBlindSpots' });
      }
      autoTable(doc, {
        columnStyles: {
          label: {
            fillColor: [51, 234, 200],
            cellWidth: 63.3
          } 
        },
        headStyles: { 
          fillColor: [62, 169, 245],
          cellWidth: 63.3
        },
        body: aggregateTeamEQBody,
        columns: aggregateTeamEQHeader,
        margin: 10
      })
      doc.save('company_report.pdf');
    }else{
      console.log('no data for report');
    }
  }

  toggleDropdown(){
    this.downloadDropdownOpen = (this.downloadDropdownOpen) ? false : true;
  }

}
