export const moduleIDOrder = [
    1521,
    1520,
    1519,
    1518,
    1517,
    1516,
    1523,
    5878,
    6412,
    8140,
    6815,
    6818,
    6821,
    18771
];