import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store, Select } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserStateModel } from 'src/app/interfaces/app.interfaces';
import { ReportService } from 'src/app/services/report.service';
import { NavState, UpdateNavComparison } from 'src/app/state/nav.state';
import { ReportState, UpdateImpactReportInfo, UpdateReportInfo, UpdateTimeTracking } from 'src/app/state/report.state';
import { UIState, UpdateReportLoading, UpdateUIMode } from 'src/app/state/ui.state';
import { UserState } from 'src/app/state/user.state';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  hasMultipleCompanies: boolean = false;
  hasMultipleDivisions: boolean = false;
  hasMultipleDepartments: boolean = false;
  hasMultipleTeams: boolean = false;
  showFilterRequiredMessage: boolean = false;
  companyHierarchy: any;
  activeNav: string = 'filter';
  userLevel: string;
  selectedDivision: any;
  divisions = [];
  selectedDepartment: any;
  departments = [];
  selectedTeam: any;
  teams = [];
  selectedLevel: any;
  levels = [];
  selectedCountry: any;
  countries = [];
  selectedRegion: any;
  regions = [];

  // alls
  allDepartments = [];
  allTeams = [];

  // for department and team level users
  parentDivisionIDs = [];
  parentDepartmentIDs = [];


  @Select(UIState.uiMode) uiMode$: Observable<string>;
  @Select(NavState.navComparison) navComparison$: Observable<string>;
  @Select(NavState.navTimeFilter) navTimeFilter$: Observable<number>;
  @Select(ReportState.companiesHierarchy) companiesHierarchy$: Observable<any>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<number>;
  @Select(UserState.user) user$: Observable<UserStateModel>;
  
  constructor(
    private store: Store,
    private reportService: ReportService,
    private router: Router
    ) { }

  ngOnInit() {
    this.uiMode$
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(res => {
      this.resetFilter();
      this.activeNav = res;
    });

    combineLatest(this.companiesHierarchy$, this.selectedCompany$, this.user$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(
      ([companiesHierarchy, selectedCompany, user]) => {
        // set user level
        this.userLevel = user.level;

        // set company hierarchy
        if(companiesHierarchy !== null && companiesHierarchy[selectedCompany]){
          this.companyHierarchy = companiesHierarchy[selectedCompany];
        }

        // multiple companies?
        if(companiesHierarchy !== null && Object.keys(companiesHierarchy).length > 1){
          this.hasMultipleCompanies = true;
        }

        // init the filter
        // for company level users
        if( (user.level === 'Admin' || user.level === 'Staff' || user.level === 'Company' || user.level === 'Division') && companiesHierarchy !== null){
          //populate hierarchies
          if(companiesHierarchy[selectedCompany].Division){
            this.populateAllDivisions(companiesHierarchy[selectedCompany].Division, true);
            this.populateAllDepartments(companiesHierarchy[selectedCompany].Division, true);
          }
          this.populateAllTeams(true);
          if(companiesHierarchy[selectedCompany].Level){
            this.populateAllLevels(companiesHierarchy[selectedCompany].Level,true);
          }
          if(companiesHierarchy[selectedCompany].Country){
            this.populateAllCountries(companiesHierarchy[selectedCompany].Country, true);
            this.populateAllRegions(companiesHierarchy[selectedCompany].Country, true);
          }
        }

        //for Division level users
        if( (user.level === 'Division') && companiesHierarchy !== null){
          //autoselect parent if only 1
          if(this.divisions.length === 1){
            this.selectedDivision = this.divisions[0].id;
            this.onDivisionChange(this.divisions[0].id, true);
          }
        }

        // for department level users
        if( user.level == 'Department' && companiesHierarchy !== null){
          //populate hierarchies
          if(companiesHierarchy[selectedCompany].Division){
            let parentDivision = companiesHierarchy[selectedCompany].Division;
            this.parentDivisionIDs = Object.keys(parentDivision);
            //populate departments
            this.parentDivisionIDs.forEach(parentDivisionID => {
              this.populateDepartments({id: parentDivisionID});
            });
          }
          this.populateAllTeams(true);
          if(companiesHierarchy[selectedCompany].Level){
            this.populateAllLevels(companiesHierarchy[selectedCompany].Level,true);
          }
          if(companiesHierarchy[selectedCompany].Country){
            this.populateAllCountries(companiesHierarchy[selectedCompany].Country, true);
            this.populateAllRegions(companiesHierarchy[selectedCompany].Country, true);
          }
          //autoselect parent if only 1
          if(this.allDepartments.length === 1){
            this.selectedDepartment = this.allDepartments[0].id;
            this.onDepartmentChange(this.allDepartments[0].id, true);
          }
        }


        // for team level users
        if( user.level == 'Team' && companiesHierarchy !== null){
          //populate hierarchies
          if(companiesHierarchy[selectedCompany].Division){
            let parentDivision = companiesHierarchy[selectedCompany].Division;
            this.parentDivisionIDs = Object.keys(parentDivision);
            this.parentDivisionIDs.forEach(parentDivisionID => {
              let parentDepartment = companiesHierarchy[selectedCompany].Division[parentDivisionID].Department;
              this.parentDepartmentIDs[parentDivisionID] = Object.keys(parentDepartment)[0];
              this.populateSingleTeam(parentDivisionID, this.parentDepartmentIDs[parentDivisionID]);
            });
          }
          if(companiesHierarchy[selectedCompany].Level){
            this.populateAllLevels(companiesHierarchy[selectedCompany].Level,true);
          }
          if(companiesHierarchy[selectedCompany].Country){
            this.populateAllCountries(companiesHierarchy[selectedCompany].Country, true);
            this.populateAllRegions(companiesHierarchy[selectedCompany].Country, true);
          }
          //autoselect parent if > 0
          if(this.allTeams.length > 0){
            this.selectedTeam = this.allTeams[0].id;
            this.onTeamChange(this.allTeams[0].id, true);
          }
        }
      }
    );

    // on time filter change
    this.navTimeFilter$.subscribe(res => {
      this.getNewReport(res);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  populateAllDivisions(divisions: any, clear: boolean = false){
    if(clear){
      this.clearSelect('divisions');
      this.clearSelect('departments');
      this.clearSelect('teams');
    }
    for (var key in divisions){
      // don't add dummy Division (used to retain hierarchy structure in payload)
      if(key !== '999999'){
        this.divisions.push({
          id: key,
          name: divisions[key].Division_Name
        });
      }
    }
    if (this.divisions.length > 1) {
      this.hasMultipleDivisions = true;
    }
  }

  populateAllDepartments(divisions: any, clear: boolean = false){
    if(clear){
      this.clearSelect('departments');
    }
    for (var key in divisions){
      this.populateDepartments({
        id: key
      });
    }

  }

  populateAllTeams(clear: boolean = false){
    const $this = this;
    if(clear){
      this.clearSelect('teams');
    }
    this.allDepartments.forEach(function(item){
      $this.populateTeams(item);
    });
  }

  populateAllRegions(countries: any, clear: boolean = false){
    if(clear){
      this.clearSelect('regions');
    }
    for (var key in countries){
      this.populateRegions({
        id: key
      });
    }
  }

  populateAllCountries(countries: any, clear: boolean = false){
    if(clear){
      this.clearSelect('countries');
      this.clearSelect('regions');
    }
    for (var key in countries){
      this.countries.push({
        id: key,
        name: countries[key].Country_Name
      });
    }
  }

  populateAllLevels(levels: any, clear: boolean = false){
    if(clear){
      this.clearSelect('levels');
    }
    for (var key in levels){
      this.levels.push({
        id: key,
        name: levels[key].Level_Name
      });
    }
  }

  populateDepartments(division: any, clear: boolean = false){
    if(clear){
      this.clearSelect('departments');
      this.clearSelect('teams');
    }
    if(Array.isArray(division)){
      division.forEach((item) => {
        this.populateSingleDepartment(item.id);
      });
    }else{
      this.populateSingleDepartment(division.id);
    }
  }

  populateSingleDepartment(division: number){
    if(
      this.companyHierarchy.Division[division] !== undefined && 
      this.companyHierarchy.Division[division].Department
      ){
      let departments = this.companyHierarchy.Division[division].Department;
      for (var key in departments){
        // check if this name matches an existing one
        let dupkey = this.checkForDuplicateNames(this.departments, departments[key].Department_Name);
        if(dupkey !== null){
          //this is a duplicate so let's merge id's
          let temp = this.departments[dupkey]
          this.departments[dupkey].id = this.departments[dupkey].id+','+key;
        }else{
          // not a duplicate so add to array
          // don't add 999999 dummy Dept for hierarchy structure
          if(key !== '999999'){
            this.departments.push({
              id: key,
              name: departments[key].Department_Name,
              division: division
            });
          }
        }

        // populate list of all departments
        if(!this.checkForDuplicateIDs(this.allDepartments,key) && key !== '999999'){
          this.allDepartments.push({
            id: key,
            name: departments[key].Department_Name,
            division: division
          });
        }
      }
      if (this.allDepartments.length > 1) {
        this.hasMultipleDepartments = true;
      }
    }
  }

  populateTeams(department: any, clear: boolean = false){
    if(clear){
      this.clearSelect('teams');
    }
    if(Array.isArray(department)){
      department.forEach((item) => {
        this.populateTeamsbyDepartment(item.id);
      });
    }else{
      this.populateTeamsbyDepartment(department.id);
    } 
  }

  populateTeamsbyDepartment(departmentid: number){
    let department = this.allDepartments.filter(item => {
      return item.id === departmentid;
    });
    this.populateSingleTeam(department[0].division,departmentid);
  }

  populateSingleTeam(division: number, department: number){
    if(
      this.companyHierarchy.Division[division].Department[department] !== undefined && 
      this.companyHierarchy.Division[division].Department[department].Team
      ){
      let teams = this.companyHierarchy.Division[division].Department[department].Team;
      for (var key in teams){
        // check if this name matches an existing one
        let dupkey = this.checkForDuplicateNames(this.teams, teams[key].Team_Name);
        if(dupkey !== null){
          //this is a duplicate so let's merge id's
          let temp = this.teams[dupkey]
          this.teams[dupkey].id = this.teams[dupkey].id+','+key;
        }else{
          // not a duplicate so add to array
          this.teams.push({
            id: key,
            name: teams[key].Team_Name,
            division: division,
            department: department
          });
        }

        // populate list of all departments
        if(!this.checkForDuplicateIDs(this.allTeams,key)){
          this.allTeams.push({
            id: key,
            name: teams[key].Team_Name,
            division: division,
            department: department
          });
        }
      }
      if (this.allTeams.length > 1) {
        this.hasMultipleTeams = true;
      }
    }
  }

  populateRegions(country: any, clear: boolean = false){
    if(clear){
      this.clearSelect('regions');
    }
    if(Array.isArray(country)){
      country.forEach((item) => {
        this.populateSingleRegion(item.id);
      });
    }else{
      this.populateSingleRegion(country.id);
    } 
  }

  populateSingleRegion(country: number){
    if(
      this.companyHierarchy.Country[country] !== undefined && 
      this.companyHierarchy.Country[country].Region
      ){
      let regions = this.companyHierarchy.Country[country].Region;
      for (var key in regions){
        this.regions.push({
          id: key,
          name: regions[key].Region_Name
        });
      }
    }
  }

  checkForDuplicateNames(items: any, name: string){
    //return the array key of the object that matches the name
    let dupkey = null;
    items.forEach(function(item, index){
      if(item.name === name){
        dupkey = index;
      }
    });
    return dupkey;
  }

  checkForDuplicateIDs(items: any, id: string){
    //return the array key of the object that matches the name
    let dupkey = false;
    items.forEach(function(item, index){
      if(item.id === id){
        dupkey = true;
      }
    });
    return dupkey;
  }

  onDivisionChange(division,clear){
    if(division !== undefined){
      this.populateDepartments({id:division}, clear);
    }else{
      // if no division selected > populate all depts and teams
      this.populateAllDepartments(this.companyHierarchy.Division, true);
      this.populateAllTeams(true);
    }
    this.flagChange();
  }

  onDepartmentChange(department,clear){
    if(department !== undefined && department.indexOf(',') === -1){
      // if department is single
      this.populateTeams({id:department},clear);
    }else if(department !== undefined && department.indexOf(',') > -1){
      // if department is multiple
      let departmentids = department.split(',');
      if(clear){
        this.clearSelect('teams');
      }
      departmentids.forEach(id => {
        this.populateTeams({id:id});
      });
    }else if(department === undefined && this.selectedDivision){
      // if no department and has division > clear teams
      this.clearSelect('teams');
    }else{
      // if no department and no division > populate all teams
      this.populateAllTeams(true);
    }
    this.flagChange();
  }

  onTeamChange(team,clear){
    this.flagChange();
  }

  onLevelChange(team,clear){
    this.flagChange();
  }

  onCountryChange(country,clear){
    if(country !== undefined){
      this.populateRegions({id:country}, clear);
    }else{
      // if no country selected > populate all regions
      this.populateAllRegions(this.companyHierarchy.Country, true);
    }
    this.flagChange();
  }

  onRegionChange(region,clear){
    this.flagChange();
  }

  flagChange(){
    this.getNewReport();
  }

  getNewReport(timeframe: number = 0){
    // reset report data
    this.store.dispatch(new UpdateReportInfo(null));
    this.store.dispatch(new UpdateImpactReportInfo(null));
    this.store.dispatch(new UpdateReportLoading({ 'reportloading' : true }));

    // default to hidden every change
    this.showFilterRequiredMessage = false;
    // build query and get new report data
    let queryStr = '';

    // if any are comma sep strings > just use the first id
    let selectedDivision = this.getFirstID(this.selectedDivision);
    let selectedDepartment = this.getFirstID(this.selectedDepartment);
    let selectedTeam = this.getFirstID(this.selectedTeam);
    let selectedLevel = this.getFirstID(this.selectedLevel);
    let selectedCountry = this.getFirstID(this.selectedCountry);
    let selectedRegion = this.getFirstID(this.selectedRegion);

    queryStr += (this.companyHierarchy !== null && this.companyHierarchy !== undefined) ? 'org_id='+this.companyHierarchy.Org_ID+'&' : '';
    queryStr += (selectedDivision !== null && selectedDivision !== undefined) ? 'division='+selectedDivision+'&' : '';
    queryStr += (selectedDepartment !== null && selectedDepartment !== undefined) ? 'department='+selectedDepartment+'&' : '';
    queryStr += (selectedTeam !== null && selectedTeam !== undefined) ? 'team='+selectedTeam+'&' : '';
    queryStr += (selectedLevel !== null && selectedLevel !== undefined) ? 'level='+selectedLevel+'&' : '';
    queryStr += (selectedCountry !== null && selectedCountry !== undefined) ? 'country='+selectedCountry+'&' : '';
    queryStr += (selectedRegion !== null && selectedRegion !== undefined) ? 'region='+selectedRegion+'&' : '';
    
    if(queryStr !== ''){
      let lastChar = queryStr.slice(-1);
      queryStr = (lastChar == '&') ? queryStr.slice(0, -1) : queryStr;
      this.reportService.getReport(queryStr,timeframe).subscribe(res => {
        this.store.dispatch(new UpdateReportInfo(res.data.mygrow_reportapi));
        this.store.dispatch(new UpdateReportLoading({ 'reportloading' : false }));
      }, err => {
        this.store.dispatch(new UpdateReportLoading({ 'reportloading' : false }));
        // no data, check why and show message
        if(this.hasMultipleDivisions && (this.selectedDivision === null || this.selectedDivision === undefined) ){
          this.showFilterRequiredMessage = true;
        }
        if(this.hasMultipleDepartments && (this.selectedDepartment === null || this.selectedDepartment === undefined) ){
          this.showFilterRequiredMessage = true;
        }
        if(this.hasMultipleTeams && (this.selectedTeam === null || this.selectedTeam === undefined) ){
          this.showFilterRequiredMessage = true;
        }
      });

      // update impact report
      this.reportService.getImpactReport(queryStr).subscribe(res => {
        this.store.dispatch(new UpdateImpactReportInfo(res.data.mygrow_reportapi));
      }, err => {
        console.log('getImpactReport err',err);
      });
    }
  }

  getFirstID(value: string){
    if(value !== null && value !== undefined && value.indexOf(',') > -1){
      let valArr = value.split(',');
      return valArr[0];
    }else{
      return value;
    }
  }

  getDivisionName(id: String){
    if(this.divisions && this.divisions.length > 0){
      let value = this.divisions.filter(item => {
        if(item.id.indexOf(',') > -1){
          let ids = item.id.split(',');
          let hasid = false;
          ids.forEach(element => {
            if(element === id){
              hasid = true;
            }
          });
          if(hasid){
            return item;
          }
        }else{
          return item.id === id;
        }
      });
      return value[0].name;
    }else{
      return '';
    }
  }

  getDepartmentName(id: String){
    if(this.departments && this.departments.length > 0){
      let value = this.departments.filter(item => {
        if(item.id.indexOf(',') > -1){
          let ids = item.id.split(',');
          let hasid = false;
          ids.forEach(element => {
            if(element === id){
              hasid = true;
            }
          });
          if(hasid){
            return item;
          }
        }else{
          return item.id === id;
        }
      });
      return value[0].name;
    }else{
      return '';
    }
  }

  getTeamName(id: String){
    if(this.teams && this.teams.length > 0){
      let value = this.teams.filter(item => {
        if(item.id.indexOf(',') > -1){
          let ids = item.id.split(',');
          let hasid = false;
          ids.forEach(element => {
            if(element === id){
              hasid = true;
            }
          });
          if(hasid){
            return item;
          }
        }else{
          return item.id === id;
        }
      });
      return value[0].name;
    }else{
      return '';
    }
  }

  resetFilter(){
    this.clearSelect();
    this.getNewReport();
  }

  displayNav(){
    return (
      this.divisions.length > 0 ||
      this.departments.length > 0 ||
      this.teams.length > 0 ||
      this.levels.length > 0 ||
      this.countries.length > 0 ||
      this.regions.length > 0
    ) ? true : false;
  }
  
  clearSelect(selection: string = 'all'){
    switch(selection) {
      case 'divisions':
        this.divisions = [];
        this.selectedDivision = null;
        break;
      case 'departments':
        this.departments = [];
        this.selectedDepartment = null;
        break;
      case 'teams':
        this.teams = [];
        this.selectedTeam = null;
        break;
      case 'levels':
        this.levels = [];
        this.selectedLevel = null;
        break;
      case 'countries':
        this.countries = [];
        this.selectedCountry = null;
        break;
      case 'regions':
        this.regions = [];
        this.selectedRegion = null;
        break;
      default:
        if(this.userLevel !== 'Division'){
          this.selectedDivision = null;
        }

        if(this.userLevel !== 'Department'){
          this.selectedDepartment = null;
        }

        if(this.userLevel !== 'Team'){
          this.selectedTeam = null;
        }

        //this.levels = [];
        this.selectedLevel = null;

        //this.countries = [];
        this.selectedCountry = null;

        //this.regions = [];
        this.selectedRegion = null;
        
    }
  }

  switchNav(mode: string){
    this.store.dispatch(new UpdateUIMode({ 'mode' : mode }));
  }

  compare(comparison: string){
    this.store.dispatch(new UpdateNavComparison({ 'comparison' : comparison }));
  }

  clearable(level: string){
    return (this.userLevel === level) ? false : true;
  }

}
