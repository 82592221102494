import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavState } from 'src/app/state/nav.state';

@Component({
  selector: 'app-key-metrics-snapshot',
  templateUrl: './key-metrics-snapshot.component.html',
  styleUrls: ['./key-metrics-snapshot.component.scss']
})
export class KeyMetricsSnapshotComponent implements OnInit {
  navComparison: string = 'none';
  selectShowing: string;
  showing = [
    { id: 'showing1', name: 'Showing 1' },
    { id: 'showing2', name: 'Showing 2' },
    { id: 'showing3', name: 'Showing 3' },
    { id: 'showing4', name: 'Showing 4' },
  ];

  @Select(NavState.navComparison) navComparison$: Observable<string>;

  constructor() { }

  ngOnInit() {
    this.navComparison$.subscribe(res => this.navComparison = res);
  }

}
