import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportState } from 'src/app/state/report.state';

export interface ProgressUserData {
  fname: string;
  lname: string;
  bootcamp: string;
  self_awareness: string;
  self_perception: string;
  self_expression: string;
  interpersonal: string;
  decision_making: string;
  stress_management: string;
}
export interface ProgressUserData2 {
  self_awareness2: string;
  self_regard: string;
  emotional_language: string;
  assertiveness: string;
  interpersonal_relationships: string;
  problem_solving: string;
  flexibility: string;
}

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe = new Subject();
  users = [];
  users2 = [];
  displayedColumns: string[] = [
    'count',
    'fname', 
    'lname', 
    'bootcamp', 
    'self_awareness', 
    'self_perception', 
    'self_expression', 
    'interpersonal', 
    'decision_making', 
    'stress_management'
  ];
  dataSource: MatTableDataSource<ProgressUserData>;
  displayedColumns2: string[] = [
    'count',
    'fname', 
    'lname',
    'self_awareness2',
    'self_regard',
    'emotional_language',
    'assertiveness',
    'interpersonal_relationships',
    'problem_solving',
    'flexibility'
  ];
  dataSource2: MatTableDataSource<ProgressUserData2>;

  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild('paginator2', {static: false}) paginator2: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort2: MatSort;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() {
  }

  ngOnInit() {
    this.reportInfo$
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(reportInfo => {
        if(
          reportInfo !== null 
          && reportInfo.users !== undefined
        ){
          this.users2 = [];
          this.users = Object.keys(reportInfo.users).map(function(key, index) {
            let level = reportInfo.users[key].level;
            let perc = reportInfo.users[key].modulepercentcomplete;
            let f101 = [
              {
                level: 1,
                name: 'bootcamp',
                perc: 0
              },
              {
                level: 2,
                name: 'self_awareness',
                perc: 0
              },
              {
                level: 3,
                name: 'self_perception',
                perc: 0
              },
              {
                level: 4,
                name: 'self_expression',
                perc: 0
              },
              {
                level: 5,
                name: 'interpersonal',
                perc: 0
              },
              {
                level: 6,
                name: 'decision_making',
                perc: 0
              },
              {
                level: 7,
                name: 'stress_management',
                perc: 0
              }
            ];
            for (let item of f101) {
              if(item.level === parseInt(level) && parseInt(level) > 0){
                item.perc = Math.trunc(parseInt(perc));
                break;
              }else if(parseInt(level) === 0){
                break;
              }else{
                item.perc = 100;
              }
            }
            return {
              fname: reportInfo.users[key].firstname,
              lname: reportInfo.users[key].lastname,
              droplets_completed: reportInfo.users[key].dropletsCompleted,
              bootcamp: f101[0].perc.toString(),
              self_awareness: f101[1].perc.toString(),
              self_perception: f101[2].perc.toString(),
              self_expression: f101[3].perc.toString(),
              interpersonal: f101[4].perc.toString(),
              decision_making: f101[5].perc.toString(),
              stress_management: f101[6].perc.toString()
            }
          });

          // sort by progress
          this.users.sort(function(a, b) {
            return b.droplets_completed - a.droplets_completed;
          });

          const $this = this;

          Object.keys(reportInfo.users).forEach(function(key, index) {
            let level = reportInfo.users[key].level;
            let perc = reportInfo.users[key].modulepercentcomplete;
            let f201 = [
              {
                level: 8,
                name: 'self_awareness2',
                perc: 0
              },
              {
                level: 9,
                name: 'self_regard',
                perc: 0
              },
              {
                level: 10,
                name: 'emotional_language',
                perc: 0
              },
              {
                level: 11,
                name: 'assertiveness',
                perc: 0
              },
              {
                level: 12,
                name: 'interpersonal_relationships',
                perc: 0
              },
              {
                level: 13,
                name: 'problem_solving',
                perc: 0
              },
              {
                level: 14,
                name: 'flexibility',
                perc: 0
              }
            ];
            for (let item of f201) {
              if(item.level === parseInt(level) && parseInt(level) > 0){
                item.perc = Math.trunc(parseInt(perc));
                break;
              }else if(parseInt(level) === 0){
                break;
              }else{
                item.perc = 100;
              }
            }
            
            if(level > 7){
              $this.users2.push({
                fname: reportInfo.users[key].firstname,
                lname: reportInfo.users[key].lastname,
                droplets_completed: reportInfo.users[key].dropletsCompleted,
                self_awareness2: f201[0].perc.toString(),
                self_regard: f201[1].perc.toString(),
                emotional_language: f201[2].perc.toString(),
                assertiveness: f201[3].perc.toString(),
                interpersonal_relationships: f201[4].perc.toString(),
                problem_solving: f201[5].perc.toString(),
                flexibility: f201[6].perc.toString()
              });
            }
          });

          // sort by progress
          this.users2.sort(function(a, b) {
            return b.droplets_completed - a.droplets_completed;
          });

          // Assign the data to the data source for the table to render
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.dataSource2 = new MatTableDataSource(this.users2);
          this.dataSource2.paginator = this.paginator2;
          this.dataSource2.sort = this.sort2;
        }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource2.paginator = this.paginator2;
    this.dataSource2.sort = this.sort2;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilter2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();

    if (this.dataSource2.paginator) {
      this.dataSource2.paginator.firstPage();
    }
  }


  cellClass(perc: number){
    if(perc == 0){
      return 'grey';
    }else if(perc <= 50){
      return 'yellow';
    }else if(perc > 50 && perc < 100){
      return 'green';
    }else if(perc == 100){
      return 'blue';
    }
  }

}
