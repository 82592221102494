import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { combineLatest, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/interfaces/app.interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  logo: string;
  private ngUnsubscribe = new Subject();

  @Select(ReportState.companiesHierarchy) companiesHierarchy$: Observable<any>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<number>;

  constructor(
    private authService: AuthService, 
    private router: Router
  ) { }

  ngOnInit() {
    combineLatest(this.companiesHierarchy$, this.selectedCompany$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(
      ([companiesHierarchy, selectedCompany]) => {
        if(companiesHierarchy !== null && companiesHierarchy[selectedCompany].Logo){
          this.logo = companiesHierarchy[selectedCompany].Logo;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  logout(){
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

}