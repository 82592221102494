import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-progress-usage',
  templateUrl: './progress-usage.component.html',
  styleUrls: ['./progress-usage.component.scss']
})
export class ProgressUsageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  hasImpactData: boolean = false;
  currentTab: number = 1;
  selectedDivision: string;
  divisions = [
      { id: 'div1', name: 'Division 1' },
      { id: 'div2', name: 'Division 2' },
      { id: 'div3', name: 'Division 3' },
      { id: 'div4', name: 'Division 4' },
  ];

  @Select(ReportState.impactReportInfo) impactReportInfo$: Observable<any>;

  constructor() { }

  ngOnInit() {
    this.impactReportInfo$
    .pipe(
        takeUntil(this.ngUnsubscribe)
    )
    .subscribe(res => {
        this.hasImpactData = (res) ? true : false;
        this.currentTab = (res) ? 1 : 2;
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
