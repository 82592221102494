import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ReportComponent } from './components/report/report.component';
import { HeaderComponent } from './components/report/header/header.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntroComponent } from './components/report/intro/intro.component';
import { SnapshotComponent } from './components/report/snapshot/snapshot.component';
import { FilterComponent } from './components/report/filter/filter.component';
import { EngagementBreakdownComponent } from './components/report/overview-graphs/engagement-breakdown/engagement-breakdown.component';
import { EqProfileOverviewComponent } from './components/report/overview-graphs/eq-profile-overview/eq-profile-overview.component';
import { EqProfileTopStrengthsComponent } from './components/report/overview-graphs/eq-profile-top-strengths/eq-profile-top-strengths.component';
import { ProgressUsageComponent } from './components/report/progress-usage/progress-usage.component';
import { EqProfileComponent } from './components/report/eq-profile/eq-profile.component';
import { EqGraphComponent } from './components/report/eq-graph/eq-graph.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgressComponent } from './components/report/progress-usage/progress/progress.component';
import { UsageComponent } from './components/report/progress-usage/usage/usage.component';
import { ImpactComponent } from './components/report/progress-usage/impact/impact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTableModule } from "@angular/material";
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { states } from './state/app.state';
import { ComparisonViewComponent } from './components/report/comparison-view/comparison-view.component';
import { KeyMetricsSnapshotComponent } from './components/report/key-metrics-snapshot/key-metrics-snapshot.component';
import { AverageWeeklyGrowthComponent } from './components/report/key-metrics-snapshot/average-weekly-growth/average-weekly-growth.component';
import { TopDepartmentStreaksComponent } from './components/report/key-metrics-snapshot/top-department-streaks/top-department-streaks.component';
import { FilterContainerComponent } from './components/report/filter-container/filter-container.component';
import { ComparisonContainerComponent } from './components/report/comparison-container/comparison-container.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UserService } from './services/user.service';
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { ReportService } from './services/report.service';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { EngagementOverviewComponent } from './components/report/overview-graphs/engagement/engagement.component';
import { GrowthscoreOverviewComponent } from './components/report/overview-graphs/growthscore/growthscore.component';
import { OverviewGraphsComponent } from './components/report/overview-graphs/overview-graphs.component';
import { TimeFilterComponent } from './components/report/time-filter/time-filter.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ReportComponent,
    HeaderComponent,
    IntroComponent,
    SnapshotComponent,
    FilterComponent,
    EngagementBreakdownComponent,
    EqProfileTopStrengthsComponent,
    EqProfileOverviewComponent,
    ProgressUsageComponent,
    EqProfileComponent,
    EqGraphComponent,
    ProgressComponent,
    UsageComponent,
    ImpactComponent,
    ComparisonViewComponent,
    KeyMetricsSnapshotComponent,
    AverageWeeklyGrowthComponent,
    TopDepartmentStreaksComponent,
    FilterContainerComponent,
    ComparisonContainerComponent,
    CompanySelectComponent,
    OverviewGraphsComponent,
    GrowthscoreOverviewComponent,
    EngagementOverviewComponent,
    TimeFilterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    NgxsModule.forRoot(states),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    //NgxsLoggerPluginModule.forRoot(),
    //NgxsResetPluginModule.forRoot(),
    HttpClientModule
  ],
  providers: [
    CookieService, 
    AuthGuardService,
    UserService,
    ReportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'googleTagManagerId',  
      useValue: 'GTM-KXWHHMQ'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
