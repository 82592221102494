import { Component, EventEmitter, OnInit, Output, AfterViewInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UpdateReportLoading } from 'src/app/state/ui.state';

@Component({
  selector: 'app-comparison-container',
  templateUrl: './comparison-container.component.html',
  styleUrls: ['./comparison-container.component.scss']
})
export class ComparisonContainerComponent implements OnInit, AfterViewInit {

  constructor(private store: Store) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    const $this = this;
    setTimeout(function(){ 
      $this.store.dispatch(new UpdateReportLoading({ 'reportloading' : false }));
    }, 1000);
  }

}
