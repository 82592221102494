import { rgbaToRgb } from '@amcharts/amcharts4/.internal/core/utils/Colors';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Select } from '@ngxs/store';
import * as Highcharts from 'highcharts';
import HC_histogram from 'highcharts/modules/histogram-bellcurve';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

HC_histogram(Highcharts);

@Component({
  selector: 'app-engagement-breakdown',
  templateUrl: './engagement-breakdown.component.html',
  styleUrls: ['./engagement-breakdown.component.scss']
})
export class EngagementBreakdownComponent implements OnInit, AfterViewInit {

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() {}

  ngOnInit() {
    let data = [];
    this.reportInfo$
    .subscribe(reportdata => {
      if(reportdata !== null && reportdata.users){
        data = [];
        for (var prop in reportdata.users) {
          if (Object.prototype.hasOwnProperty.call(reportdata.users, prop)) {
            data.push(+reportdata.users[prop].engagement);
          }
        }
        this.renderGraph(data);
      }
      
    });
  }

  renderGraph(data: any){
    let options: any = {
      yAxis:{
        min: 0, 
        // max: this.data.length,
        title:{
          text:'Users'
        },
        labels:{
          formatter: function() {
            return (this.value * 100)+"%";
          }
        }
      },
      xAxis:{
        min: 0, 
        max: 7,
        title:{
          text:'Days per Week'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title:{
        text:''
      },
      series: [
        {
          name: 'Histogram',
          type: 'bellcurve',
          xAxis: 0,
          yAxis: 0,
          baseSeries: 's1',
          enableMouseTracking: false,
          borderColor: '#000000'
        }, {
          name: 'Data',
          type: 'scatter',
          data: data,
          visible: false,
          id: 's1',
          marker: {
              radius: 1.5
          }
        }
      ]
    }
    Highcharts.chart('engagementbreakdown', options);
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {

  }

}