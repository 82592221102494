import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import * as moment from "moment";
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { UpdateCompaniesHierarchy, UpdateReportInfo, UpdateSelectedCompany } from '../state/report.state';
import { UpdateUserInfo } from '../state/user.state';
import { UpdateNavComparison } from '../state/nav.state';
import { UpdateReportLoading, UpdateUIMode } from '../state/ui.state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //baseURI: string = 'https://fccwip.co.za:443/api';
  //baseURI: string = 'http://phpstack-556615-1827287.cloudwaysapps.com/api';
  baseURI: string = 'https://progressbackend.mygrow.me/api';
  
  constructor(
    private http: HttpClient, 
    private cookieService: CookieService,
    private router: Router,
    private store: Store
  ) { }

  login(username: string, password: string): Observable<any> {
    if(!username || !password){
      return null;
    }
    const headers = {
      'x-api-key': '80EE570B4D6C25FA88FE47199666C8BC',
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    let body = 'username='+username+'&password='+password;
    return this.http.post<any[]>(this.baseURI+'/user/request_token',body,{headers: headers});
  }

  setSession(authResult: any){
    let expiredDate = new Date();
    expiredDate.setHours( expiredDate.getHours() + authResult.data.expiration.hours );
    //expiredDate.setHours( expiredDate.getHours() + 24 );// TODO: remove once Kirsty updates on her side (0.25 hours was breaking things)
    
    // //set localStorage TODO: remove if cookies are working
    // localStorage.setItem('id_token',JSON.stringify(authResult.data.token));
    // localStorage.setItem('user_id',JSON.stringify(authResult.data.id));
    // localStorage.setItem('session_expires',JSON.stringify(moment(expiredDate).format("YYYY-MM-DD HH:mm:ss")));

    // set secure http cookie to store token
    this.cookieService.set( 
      'id_token', 
      JSON.stringify(authResult.data.token), 
      {
        expires: expiredDate, 
        sameSite: 'Strict',
        secure: true // NOTE: enable this when live (over https)
      }
    );

    // set secure http cookie to store userid
    this.cookieService.set( 
      'user_id', 
      JSON.stringify(authResult.data.id), 
      {
        expires: expiredDate, 
        sameSite: 'Strict',
        secure: true // NOTE: enable this when live (over https)
      }
    );

    // set cookie to store session expiration
    this.cookieService.set( 
      'session_expires', 
      JSON.stringify(moment(expiredDate).format("YYYY-MM-DD HH:mm:ss")), 
      {
        expires: expiredDate, 
        sameSite: 'Strict',
        secure: true // NOTE: enable this when live (over https)
      }
    );
  }

  logout(){
    this.cookieService.deleteAll(); 
    //this.deleteAllLocalstorage(); //TODO: remove if cookies are working

    // reset report and user state
    this.store.dispatch(new UpdateUserInfo({ 
      'id' : '', 
      'first_name' : '',
      'last_name' : '',
      'level' : '',
      'email' : '',
      'companies' : []
    }));
    this.store.dispatch(new UpdateReportInfo(null));
    this.store.dispatch(new UpdateNavComparison({ 'comparison' : 'none' }));
    this.store.dispatch(new UpdateUIMode({ 'mode' : 'filter' }));
    this.store.dispatch(new UpdateReportLoading({ 'reportloading' :true }));
    this.store.dispatch(new UpdateCompaniesHierarchy(null));
    this.store.dispatch(new UpdateSelectedCompany(null));
    
  }

  isLoggedIn(){
    const token = this.getToken();
    const userid = this.getUserid();
    let result = (moment().isBefore(this.getExpiration()) && token && userid)? true : false;
    return result;
  }

  getExpiration(){
    const session_expires = this.cookieService.get('session_expires');
    //const session_expires = localStorage.getItem('session_expires'); //TODO: remove if cookies are working
    return (session_expires) ? JSON.parse(session_expires) : false;
  }

  getToken(){
    const token: string = this.cookieService.get('id_token');
    //const token: string = localStorage.getItem('id_token'); //TODO: remove if cookies are working
    return (token) ? JSON.parse(token) : false;
  }

  getUserid(){
    const userid: string = this.cookieService.get('user_id');//TODO: cookies disabled because of secure cookie not working
    //const userid: string = localStorage.getItem('user_id');
    return (userid) ? JSON.parse(userid) : false;
  }

  // localstorage options  //TODO: remove if cookies are working

  deleteAllLocalstorage(){
    localStorage.removeItem('user_id');
    localStorage.removeItem('id_token');
    localStorage.removeItem('session_expires');
  }

}