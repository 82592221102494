import { NavState } from './nav.state';
import { ReportState } from './report.state';
import { UIState } from './ui.state';
import { UserState } from './user.state';

export const states = [
  UserState,
  UIState,
  NavState,
  ReportState
];