import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserStateModel } from 'src/app/interfaces/app.interfaces';
import { NavState, UpdateTimeFilter } from 'src/app/state/nav.state';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss']
})
export class TimeFilterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  selectedTimeFrame: any = 0;
  timeFrames = [
      { id: 0, name: 'Last 7 Days' },
      { id: 1, name: 'Last 14 Days' },
      { id: 2, name: 'Last 30 Days' },
      { id: 3, name: 'Last 3 Months' },
      { id: 4, name: 'Last 6 Months' },
      { id: 5, name: 'Last 1 Year' }
  ];

  @Select(NavState.navTimeFilter) navTimeFilter$: Observable<UserStateModel>;

  constructor(private store: Store) { }

  ngOnInit() {
    // check timeFilter
    this.navTimeFilter$
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(res => {
      this.selectedTimeFrame = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ontimeFrameChange(timeFrame: number){
    if(timeFrame !== undefined){
      this.store.dispatch(new UpdateTimeFilter({ 'timeframe' : timeFrame }));
    }
  }

}