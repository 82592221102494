import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-overview-graphs',
  templateUrl: './overview-graphs.component.html',
  styleUrls: ['./overview-graphs.component.scss']
})
export class OverviewGraphsComponent implements OnInit, AfterContentInit {
  currentTab: number = 1;
  displayEQData: string = 'none';
  displayEngagementBreakdown: boolean = false;
  loaded: boolean = false;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor() { }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportdata => {
      // check if we can display eq data
      if(reportdata !== null && reportdata.CompanyEQData){
        // if SelfandPeer
        if(
          reportdata.CompanyEQData.SelfAndPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] >= 5
        ){
          this.displayEQData = 'SelfAndPeer';
        }else if(
          (reportdata.CompanyEQData.SelfOnly && 
          reportdata.CompanyEQData.TotalSelfOnly && 
          !reportdata.CompanyEQData.SelfAndPeer && 
          !reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfOnly[1] >= 5) 
          ||
          (reportdata.CompanyEQData.SelfOnly && 
          reportdata.CompanyEQData.TotalSelfOnly && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] <= 5 &&
          reportdata.CompanyEQData.TotalSelfOnly[1] >= 5)
        ){
          this.displayEQData = 'SelfOnly';
        }
      }
      if(
        (
          reportdata !== null && 
          reportdata.users !== undefined && 
          reportdata.users && Object.keys(reportdata.users).length > 1
        ) 
        ||
        (
          reportdata !== null && 
          reportdata.users !== undefined && 
          reportdata.CompanyEQData.averageGrowthScore &&
          reportdata.CompanyEQData.averageGrowthScore > 0
        )
        ){
        //  if more than one user
        this.displayEngagementBreakdown = true;
      }
      if(this.displayEQData == 'none' && !this.displayEngagementBreakdown){
        this.currentTab = 3;
      }else if(this.displayEQData == 'none' && this.displayEngagementBreakdown){
        this.currentTab = 2;
      }
    });
  }

  ngAfterContentInit() {
    this.loaded = true;
  }

}
