import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { UserInfoPayload, UserStateModel, Company, ReportModel } from '../interfaces/app.interfaces';

export class UpdateSelectedCompany {
    static readonly type = '[Report] update selected company';
    constructor(public payload: number) { }
}

export class UpdateCompaniesHierarchy {
  static readonly type = '[Report] update companies hierarchy';
  constructor(public payload: any) { }
}

export class UpdateReportInfo {
  static readonly type = '[Report] update report info';
  constructor(public payload: any) { }
}

export class UpdateTimeTracking {
  static readonly type = '[Report] update time tracking';
  constructor(public payload: any) { }
}

export class UpdateModulesInfo {
  static readonly type = '[Report] update module info';
  constructor(public payload: any) { }
}

export class UpdateImpactReportInfo {
  static readonly type = '[Report] update impact report info';
  constructor(public payload: any) { }
}

//state
@State<ReportModel>({
  name: 'report',
  defaults: {
    selectedCompany: null,
    companiesHierarchy: null,
    reportInfo: null,
    modulesInfo: null,
    impactReportInfo: null
  }
})
export class ReportState {
  constructor() { }

  @Selector()
  public static selectedCompany(state: ReportModel) {
    return state.selectedCompany;
  }

  @Selector()
  public static companiesHierarchy(state: ReportModel) {
    return state.companiesHierarchy;
  }

  @Selector()
  public static reportInfo(state: ReportModel) {
    return state.reportInfo;
  }

  @Selector()
  public static modulesInfo(state: ReportModel) {
    return state.modulesInfo;
  }

  @Selector()
  public static impactReportInfo(state: ReportModel) {
    return state.impactReportInfo;
  }

  @Action(UpdateSelectedCompany)
  updateSelectedCompany(ctx: StateContext<ReportModel>, action: UpdateSelectedCompany) {
    ctx.patchState({
        selectedCompany: action.payload
    });
  }

  @Action(UpdateCompaniesHierarchy)
  updateCompaniesHierarchy(ctx: StateContext<ReportModel>, action: UpdateCompaniesHierarchy) {
    ctx.patchState({
      companiesHierarchy: action.payload
    });
  }

  @Action(UpdateReportInfo)
  updateReportInfo(ctx: StateContext<ReportModel>, action: UpdateReportInfo) {
    ctx.patchState({
      reportInfo: action.payload
    });
  }

  @Action(UpdateModulesInfo)
  updateModulesInfo(ctx: StateContext<ReportModel>, action: UpdateModulesInfo) {
    ctx.patchState({
      modulesInfo: action.payload
    });
  }

  @Action(UpdateImpactReportInfo)
  updateImpactReportInfo(ctx: StateContext<ReportModel>, action: UpdateImpactReportInfo) {
    ctx.patchState({
      impactReportInfo: action.payload
    });
  }

}