import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import * as moment from "moment";
import { Select, Store } from '@ngxs/store';
import { Company, GetUserDetail, UserStateModel } from '../interfaces/app.interfaces';
import { UpdateUserInfo, UserState } from '../state/user.state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //baseURI: string = 'https://fccwip.co.za:443/api';
  //baseURI: string = 'http://phpstack-556615-1827287.cloudwaysapps.com/api';
  baseURI: string = 'https://progressbackend.mygrow.me/api';

  @Select(UserState.user) user$: Observable<UserStateModel>;

  constructor(private http: HttpClient, private store: Store) {
  }

  getUserDetail(userid: string){
    return this.http.get<GetUserDetail>(this.baseURI+'/mygrow_reportusers/detail?id='+userid);
  }

  processCompanies(companies: any): Company[] {
    let outputCompanies = Object.keys(companies).map(function(key, index) {
      return {
        id: key,
        name: companies[key]
      }
    });
    return outputCompanies;
  }

}