import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserStateModel } from 'src/app/interfaces/app.interfaces';
import { ReportService } from 'src/app/services/report.service';
import { NavState } from 'src/app/state/nav.state';
import { ReportState, UpdateReportInfo } from 'src/app/state/report.state';
import { UserState } from 'src/app/state/user.state';
import * as moment from 'moment';

@Component({
  selector: 'app-comparison-view',
  templateUrl: './comparison-view.component.html',
  styleUrls: ['./comparison-view.component.scss']
})
export class ComparisonViewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  navComparison: string = 'none';
  selectedSortby: string;
  companyHierarchy: any;
  userLevel: string;
  comparisonLoading: boolean = true;
  comparisonItems = [];
  departments = [];
  allDepartments = [];
  teams = [];
  allTeams = [];
  regions = [];

  sortby = [
      { id: 'sort1', name: 'Sort 1' },
      { id: 'sort2', name: 'Sort 2' },
      { id: 'sort3', name: 'Sort 3' },
      { id: 'sort4', name: 'Sort 4' },
  ];
  selectShowing: string;
  showing = [
    { id: 'showing1', name: 'Showing 1' },
    { id: 'showing2', name: 'Showing 2' },
    { id: 'showing3', name: 'Showing 3' },
    { id: 'showing4', name: 'Showing 4' },
  ];

  @Select(NavState.navComparison) navComparison$: Observable<string>;
  @Select(ReportState.companiesHierarchy) companiesHierarchy$: Observable<any>;
  @Select(ReportState.selectedCompany) selectedCompany$: Observable<number>;
  @Select(UserState.user) user$: Observable<UserStateModel>;
  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor(private store: Store, private reportService: ReportService) { }

  ngOnInit() {
    this.comparisonItems = [];
    combineLatest(this.companiesHierarchy$, this.selectedCompany$, this.user$, this.navComparison$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(
      ([companiesHierarchy, selectedCompany, user, navComparison]) => {
        this.navComparison = navComparison;
        // set user level
        this.userLevel = user.level;

        // set company hierarchy
        if(companiesHierarchy !== null && companiesHierarchy[selectedCompany]){
          this.companyHierarchy = companiesHierarchy[selectedCompany];
        }

        // init the comparison
        if(companiesHierarchy[selectedCompany].Level && navComparison == 'levels'){
          this.getNewReport('Level');
        }
        if(companiesHierarchy[selectedCompany].Country && navComparison == 'countries'){
          this.getNewReport('Country');
        }
        if(companiesHierarchy[selectedCompany].Country && navComparison == 'regions'){
          this.getNewReport('Region');
        }
        if(companiesHierarchy[selectedCompany].Division && navComparison == 'divisions'){
          this.getNewReport('Division');
        }
        if(companiesHierarchy[selectedCompany].Division && navComparison == 'departments'){
          this.getNewReport('Department');
        }
        if(companiesHierarchy[selectedCompany].Division && navComparison == 'teams'){
          this.getNewReport('Team');
        }

    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getNewReport(reportlevel: string, final: boolean = false, id:number = 0){
    this.comparisonItems = [];
    this.comparisonLoading = true;
    // build query and get new report data
    let queryStr = '';
    queryStr += (this.companyHierarchy !== null && this.companyHierarchy !== undefined) ? 'org_id='+this.companyHierarchy.Org_ID+'&reportlevel='+reportlevel : '';
    
    let lastChar = queryStr.slice(-1);
    queryStr = (lastChar == '&') ? queryStr.slice(0, -1) : queryStr;
    

    const today = moment().format('YYYY-MM-DD');

    this.reportService.getComparisons(queryStr).subscribe(res => {
      if(res.data.mygrow_reportcompareapi !== undefined){
        Object.keys(res.data.mygrow_reportcompareapi).forEach((key) => {
          if(key !== 'CompanyAverageGS' && key !== 'CompanyActiveUsers' && key != 'CompanyAverageDropPerWW'){
            let item = res.data.mygrow_reportcompareapi[key][today];
            let variance = 0;
            let growthscore = item.AverageGS;
            let people = (item.ActiveUsers) ? item.ActiveUsers : 0;
            let comanyAverageGrowthScore = res.data.mygrow_reportcompareapi.CompanyAverageGS[today];
            growthscore = (item.AverageGS) ? Math.round((item.AverageGS + Number.EPSILON) * 100) / 100 : 0;
            if(item.AverageGS && comanyAverageGrowthScore){
              variance = ((item.AverageGS - comanyAverageGrowthScore) / comanyAverageGrowthScore) * 100;
              variance = Math.round((variance + Number.EPSILON) * 100) / 100;
            }
            this.comparisonItems.push({
              'name' : key,
              'people' : people,
              'growthscore' : growthscore,
              'variance' : variance
            });
          }
        })
        this.comparisonItems.sort((a, b) => b.growthscore - a.growthscore);
        this.comparisonLoading = false;
      }
    });
  }

}