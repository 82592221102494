import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { ReportComponent } from '../components/report/report.component';
import { AuthGuardService as AuthGuard } from '../services/auth-guard.service';
import { CompanySelectComponent } from '../components/company-select/company-select.component';

const routes: Routes = [
  { 
    path: '', 
    component: LoginComponent 
  },
  { 
    path: 'report', 
    component: ReportComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'company-select', 
    component: CompanySelectComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
