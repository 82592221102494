import { Component, Inject, NgZone, PLATFORM_ID, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-average-weekly-growth',
  templateUrl: './average-weekly-growth.component.html',
  styleUrls: ['./average-weekly-growth.component.scss']
})
export class AverageWeeklyGrowthComponent implements OnInit {
    private chart: am4charts.XYChart;

    constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}
  
    // Run the function only in the browser
    browserOnly(f: () => void) {
      if (isPlatformBrowser(this.platformId)) {
        this.zone.runOutsideAngular(() => {
          f();
        });
      }
    }
  
    ngOnInit() {
    }
  
    ngAfterViewInit() {
      // Chart code goes in here
      this.browserOnly(() => {
        am4core.useTheme(am4themes_animated);
        am4core.options.autoSetClassName = true;
  
        let chart = am4core.create("growthengagementbreakdown", am4charts.XYChart);
        // Add data
        var data = [];
        var engagement = 30;
        var growthscore = 30;
        for(var i = 1; i < 8; i++){
            engagement -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
            growthscore -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
            data.push({day:i, engagement: engagement, growthscore: growthscore});
        }

        chart.data = data;

  
        // Create axes
        var dayAxis = chart.xAxes.push(new am4charts.ValueAxis());
        dayAxis.renderer.minGridDistance = 40;
        dayAxis.renderer.minLabelPosition = 0.1;
        dayAxis.renderer.maxLabelPosition = 0.9;
        dayAxis.maxPrecision = 0;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // dayAxis.renderer.grid.template.stroke = am4core.color("#EDEDED");
        // valueAxis.renderer.grid.template.stroke = am4core.color("#EDEDED");

        // Create engagement series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "engagement";
        series.dataFields.valueX = "day";
        series.tooltipText = "{engagement}";
        series.stroke = am4core.color("#3EA9F5");

        series.tooltip.pointerOrientation = "vertical";

        // create growthscore Series
        var series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "growthscore";
        series2.dataFields.valueX = "day";
        series2.tooltipText = "{growthscore}";
        series2.stroke = am4core.color("#33EAC8");

        series2.tooltip.pointerOrientation = "vertical";

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dayAxis;
        chart.paddingLeft = 0;

        this.chart = chart;
      });
    }
  
    ngOnDestroy() {
      // Clean up chart when the component is removed
      this.browserOnly(() => {
        if (this.chart) {
          this.chart.dispose();
        }
      });
    }

}
