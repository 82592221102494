import { Component, Inject, NgZone, PLATFORM_ID, OnInit, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReportState } from 'src/app/state/report.state';

@Component({
  selector: 'app-eq-graph',
  templateUrl: './eq-graph.component.html',
  styleUrls: ['./eq-graph.component.scss']
})
export class EqGraphComponent implements OnInit {
  eqGraphShow: boolean  = true;
  eqChartShow: boolean  = false;
  displayChart: boolean = false;
  footertext: string = '';

  private chart: am4charts.XYChart;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportdata => {
      if(reportdata !== null && reportdata.CompanyEQData){
        let data = [];
        // if SelfandPeer
        if(
          reportdata.CompanyEQData.SelfAndPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer &&
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] >= 5
        ){
          // if 5 or over
          this.footertext = '*Based on '+reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1]+' Self-Assessments with '+reportdata.CompanyEQData.TotalPeers[1]+' Peer-Assessments.';
          // TODO: use first and second rounds? check for first round?
          // get self data
          data['Self'] = reportdata.CompanyEQData.SelfAndPeer[1]['Self'];

          // get peer data
          data['Peer'] = reportdata.CompanyEQData.SelfAndPeer[1]['Peer'];
          
          // render graph
          this.displayChart = true;
          this.initCharts(data, true);
        }else if(
          (reportdata.CompanyEQData.SelfOnly && 
            reportdata.CompanyEQData.TotalSelfOnly && 
            !reportdata.CompanyEQData.SelfAndPeer && 
            !reportdata.CompanyEQData.TotalSelfAssessmentswithPeer &&
            reportdata.CompanyEQData.TotalSelfOnly[1] >= 5) 
            ||
            (reportdata.CompanyEQData.SelfOnly && 
              reportdata.CompanyEQData.TotalSelfOnly && 
              reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
              reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] < 5 &&
              reportdata.CompanyEQData.TotalSelfOnly[1] >= 5)
        ){
          // if SelfOnly
          // if 5 or over
          this.footertext = '*Based on '+reportdata.CompanyEQData.TotalSelfOnly[1]+' Self-Assessments.';
          // get self data
          data['Self'] = reportdata.CompanyEQData.SelfOnly[1];

          // render graph
          this.displayChart = true;
          this.initCharts(data);
        }else{
          this.footertext = 'Not enough data to render the graph.';
          this.displayChart = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  initCharts(data: any, peers: boolean = false){
    // Chart code goes in here
    this.browserOnly(() => {
      //Radar Chart
      let chartradar = am4core.create("eqgraph", am4charts.RadarChart);
      // Make the chart fade-in on init
      chartradar.hiddenState.properties.opacity = 0;

      // Add cursor
      chartradar.cursor = new am4charts.RadarCursor();

      if(data['Peer'] && data['Self']){
        var assessmentdate = 'EQ360 Assessment Results';
        var selfselfregard = data['Self']['Self Regard'];
        var peerselfregard = data['Peer']['Self Regard'];
        var selfselfactual = data['Self']['Self Actualisation'];
        var peerselfactual = data['Peer']['Self Actualisation'];
        var selfemoselfaware = data['Self']['Emotional Self-Awareness'];
        var peeremoselfaware = data['Peer']['Emotional Self-Awareness'];
        var selfemoexpress = data['Self']['Emotional Expression'];
        var peeremoexpress = data['Peer']['Emotional Expression'];
        var selfassert = data['Self']['Assertiveness'];
        var peerassert = data['Peer']['Assertiveness'];
        var selfindependance = data['Self']['Independence'];
        var peerindependance = data['Peer']['Independence'];
        var selfinterrelate = data['Self']['Interpersonal Relationships'];
        var peerinterrelate = data['Peer']['Interpersonal Relationships'];
        var selfempathy = data['Self']['Empathy'];
        var peerempathy = data['Peer']['Empathy'];
        var selfsocialresp = data['Self']['Social Responsibility'];
        var peersocialresp = data['Peer']['Social Responsibility'];
        var selfproblemsolve = data['Self']['Problem Solving'];
        var peerproblemsolve = data['Peer']['Problem Solving'];
        var selfrealitytest = data['Self']['Reality Testing'];
        var peerrealitytest = data['Peer']['Reality Testing'];
        var selfimpulsecontrol = data['Self']['Impulse Control'];
        var peerimpulsecontrol = data['Peer']['Impulse Control'];
        var selfflexibility = data['Self']['Flexibility'];
        var peerflexibility = data['Peer']['Flexibility'];
        var selfstress = data['Self']['Stress Tolerance'];
        var peerstress = data['Peer']['Stress Tolerance'];
        var selfoptimism = data['Self']['Optimism'];
        var peeroptimism = data['Peer']['Optimism'];

        // Set data
        chartradar.data = [{
          "direction": "Self Regard",
          "value": selfselfregard,
          "pvalue": peerselfregard,
          "explain": "<strong>Self-Regard</strong> is respecting oneself<br/>while understanding and accepting <br/>one’s strengths and weaknesses.<br/> Self-Regard is often associated <br/>with feelings of inner <br/>strength and self-confidence."
        }, {
          "direction": "Self\nActualisation",
          "value": selfselfactual,
          "pvalue": peerselfactual,
          "explain": "<strong>Self-Actualization</strong> is the willingness to persistently try to improve oneself and engage in the <br/>pursuit of personally relevant and meaningful objectives<br/> that lead to rich and enjoyable life."
        }, {
          "direction": "Emotional\nSelf-Awareness",
          "value": selfemoselfaware,
          "pvalue": peeremoselfaware,
          "explain": "<strong>Emotional Self-Awareness</strong> includes recognising and understanding your own emotions. <br/>This includes the ability to differentiate between subtleties in one’s own emotions while understanding<br/> the cause of these emotions and the impact they have on your thoughts and actions, and those of others."
        }, {
          "direction": "Emotional\nExpression",
          "value": selfemoexpress,
          "pvalue": peeremoexpress,
          "explain": "<strong>Emotional Expression</strong> is openly expressing one’s feelings verbally and non-verbally."
        }, {
          "direction": "Assertiveness",
          "value": selfassert,
          "pvalue": peerassert,
          "explain": "<strong>Assertiveness</strong> involves communicating feelings, beliefs and thoughts openly, <br/>and defending personal rights and values in a socially acceptable, non-offensive, and non-destructive manner."
        }, {
          "direction": "Independence",
          "value": selfindependance,
          "pvalue": peerindependance,
          "explain": "<strong>Independence</strong> is the ability to be self directed and free<br/>from emotional dependency on others. Decision-making, planning, and daily tasks are completed autonomously."
        }, {
          "direction": "Interpersonal\nRelationships",
          "value": selfinterrelate,
          "pvalue": peerinterrelate,
          "explain": "<strong>Interpersonal Relationships</strong> refers to the skill of developing <br/>and maintaining mutually satisfying relationships that are characterised by trust and compassion."
        }, {
          "direction": "Empathy",
          "value": selfempathy,
          "pvalue": peerempathy,
          "explain": "<strong>Empathy</strong> is recognising, understanding, and appreciating how other people feel.<br/>Empathy involves being able to articulate your understanding of<br/>another’s perspective and behaving in a way that respects others’ feelings."
        }, {
          "direction": "Social\nResponsibility",
          "value": selfsocialresp,
          "pvalue": peersocialresp,
          "explain": "<strong>Social Responsibility</strong> is willingly contributing to society, to one’s social groups, and generally to the welfare of others.<br/>Social Responsibility involves acting responsibly, having social consciousness, and showing concern for the greater community."
        }, {
          "direction": "Problem Solving",
          "value": selfproblemsolve,
          "pvalue": peerproblemsolve,
          "explain": "<strong>Problem Solving</strong>is the ability to find solutions to problems in situations where emotions are involved.<br/>Problem Solving includes the ability to understand how emotions impact decision making."
        }, {
          "direction": "Reality\nTesting",
          "value": selfrealitytest,
          "pvalue": peerrealitytest,
          "explain": "<strong>Reality Testing</strong> is the capacity to remain objective by seeing things as they really are.<br/>This capacity involves recognising when emotions or personal bias can cause one to be less objective."
        }, {
          "direction": "Impulse\nControl",
          "value": selfimpulsecontrol,
          "pvalue": peerimpulsecontrol,
          "explain": "<strong>Impulse Control</strong> is the ability to resist or delay an impulse,<br/>drive or temptation to act, and involves avoiding rash behaviors and decision making."
        }, {
          "direction": "Flexibility",
          "value": selfflexibility,
          "pvalue": peerflexibility,
          "explain": "<strong>Flexibility</strong> is adapting emotions, thoughts, and behaviours to<br/>unfamiliar, unpredictable, and dynamic circumstances or ideas."
        }, {
          "direction": "Stress\nTolerance",
          "value": selfstress,
          "pvalue": peerstress,
          "explain": "<strong>Stress Tolerance</strong> involves coping with stressful or difficult situations<br/>and believing that one can manage or influence situations in a positive manner."
        }, {
          "direction": "Optimism",
          "value": selfoptimism,
          "pvalue": peeroptimism,
          "explain": "<strong>Optimism</strong> is an indicator of one’s positive attitude and outlook on life.<br/>It involves remaining hopeful and resilient despite occasional setbacks."
        }]
      }else{
        var assessmentdate = 'EQ360 Assessment Results';
        var selfselfregard = data['Self']['Self Regard'];
        var selfselfactual = data['Self']['Self Actualisation'];
        var selfemoselfaware = data['Self']['Emotional Self-Awareness'];
        var selfemoexpress = data['Self']['Emotional Expression'];
        var selfassert = data['Self']['Assertiveness'];
        var selfindependance = data['Self']['Independence'];
        var selfinterrelate = data['Self']['Interpersonal Relationships'];
        var selfempathy = data['Self']['Empathy'];
        var selfsocialresp = data['Self']['Social Responsibility'];
        var selfproblemsolve = data['Self']['Problem Solving'];
        var selfrealitytest = data['Self']['Reality Testing'];
        var selfimpulsecontrol = data['Self']['Impulse Control'];
        var selfflexibility = data['Self']['Flexibility'];
        var selfstress = data['Self']['Stress Tolerance'];
        var selfoptimism = data['Self']['Optimism'];

        // Set data
        chartradar.data = [{
          "direction": "Self Regard",
          "value": selfselfregard,
          "explain": "<strong>Self-Regard</strong> is respecting oneself<br/>while understanding and accepting <br/>one’s strengths and weaknesses.<br/> Self-Regard is often associated <br/>with feelings of inner <br/>strength and self-confidence."
        }, {
          "direction": "Self\nActualisation",
          "value": selfselfactual,
          "explain": "<strong>Self-Actualization</strong> is the willingness to persistently try to improve oneself and engage in the <br/>pursuit of personally relevant and meaningful objectives<br/> that lead to rich and enjoyable life."
        }, {
          "direction": "Emotional\nSelf-Awareness",
          "value": selfemoselfaware,
          "explain": "<strong>Emotional Self-Awareness</strong> includes recognising and understanding your own emotions. <br/>This includes the ability to differentiate between subtleties in one’s own emotions while understanding<br/> the cause of these emotions and the impact they have on your thoughts and actions, and those of others."
        }, {
          "direction": "Emotional\nExpression",
          "value": selfemoexpress,
          "explain": "<strong>Emotional Expression</strong> is openly expressing one’s feelings verbally and non-verbally."
        }, {
          "direction": "Assertiveness",
          "value": selfassert,
          "explain": "<strong>Assertiveness</strong> involves communicating feelings, beliefs and thoughts openly, <br/>and defending personal rights and values in a socially acceptable, non-offensive, and non-destructive manner."
        }, {
          "direction": "Independence",
          "value": selfindependance,
          "explain": "<strong>Independence</strong> is the ability to be self directed and free<br/>from emotional dependency on others. Decision-making, planning, and daily tasks are completed autonomously."
        }, {
          "direction": "Interpersonal\nRelationships",
          "value": selfinterrelate,
          "explain": "<strong>Interpersonal Relationships</strong> refers to the skill of developing <br/>and maintaining mutually satisfying relationships that are characterised by trust and compassion."
        }, {
          "direction": "Empathy",
          "value": selfempathy,
          "explain": "<strong>Empathy</strong> is recognising, understanding, and appreciating how other people feel.<br/>Empathy involves being able to articulate your understanding of<br/>another’s perspective and behaving in a way that respects others’ feelings."
        }, {
          "direction": "Social\nResponsibility",
          "value": selfsocialresp,
          "explain": "<strong>Social Responsibility</strong> is willingly contributing to society, to one’s social groups, and generally to the welfare of others.<br/>Social Responsibility involves acting responsibly, having social consciousness, and showing concern for the greater community."
        }, {
          "direction": "Problem Solving",
          "value": selfproblemsolve,
          "explain": "<strong>Problem Solving</strong>is the ability to find solutions to problems in situations where emotions are involved.<br/>Problem Solving includes the ability to understand how emotions impact decision making."
        }, {
          "direction": "Reality\nTesting",
          "value": selfrealitytest,
          "explain": "<strong>Reality Testing</strong> is the capacity to remain objective by seeing things as they really are.<br/>This capacity involves recognising when emotions or personal bias can cause one to be less objective."
        }, {
          "direction": "Impulse\nControl",
          "value": selfimpulsecontrol,
          "explain": "<strong>Impulse Control</strong> is the ability to resist or delay an impulse,<br/>drive or temptation to act, and involves avoiding rash behaviors and decision making."
        }, {
          "direction": "Flexibility",
          "value": selfflexibility,
          "explain": "<strong>Flexibility</strong> is adapting emotions, thoughts, and behaviours to<br/>unfamiliar, unpredictable, and dynamic circumstances or ideas."
        }, {
          "direction": "Stress\nTolerance",
          "value": selfstress,
          "explain": "<strong>Stress Tolerance</strong> involves coping with stressful or difficult situations<br/>and believing that one can manage or influence situations in a positive manner."
        }, {
          "direction": "Optimism",
          "value": selfoptimism,
          "explain": "<strong>Optimism</strong> is an indicator of one’s positive attitude and outlook on life.<br/>It involves remaining hopeful and resilient despite occasional setbacks."
        }]
      }
      



      // Set chart padding
      chartradar.padding(10, 10, 10, 10);

      // Set up axes
      var radarcategoryAxis = chartradar.xAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererCircular>());
      radarcategoryAxis.renderer.grid.template.location = 0;
      radarcategoryAxis.dataFields.category = "direction";
      radarcategoryAxis.renderer.minGridDistance = 5;
      radarcategoryAxis.cursorTooltipEnabled = false;

      var radarlabel = radarcategoryAxis.renderer.labels.template;
      radarlabel.wrap = true;
      radarlabel.maxWidth = 120;
      radarlabel.textAlign = "middle";
      radarlabel.tooltipHTML = "{explain}";

      var radarvalueAxis = chartradar.yAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererRadial>());
      radarvalueAxis.min = 0;
      radarvalueAxis.max = 10;

      var range = radarcategoryAxis.axisRanges.create();
      range.category = "Self Regard";
      range.endCategory = "Emotional\nSelf-Awareness";
      range.axisFill.fill = am4core.color("#f69497");
      range.axisFill.fillOpacity = 1;
      range.label.disabled = true;
      range.axisFill.interactionsEnabled = true;
      range.axisFill.isMeasured = true;
      range.locations.category = 0;
      range.locations.endCategory = 0.5;

      var range2 = radarcategoryAxis.axisRanges.create();
      range2.category = "Emotional\nSelf-Awareness";
      range2.endCategory = "Independence";
      range2.label.disabled = true;
      range2.axisFill.fill = am4core.color("#fec19b");
      range2.axisFill.fillOpacity = 1;
      range2.locations.category = 0.5;
      range2.locations.endCategory = 0.5;

      var range3 = radarcategoryAxis.axisRanges.create();
      range3.category = "Independence";
      range3.endCategory = "Social\nResponsibility";
      range3.label.disabled = true;
      range3.axisFill.fill = am4core.color("#fde79f");
      range3.axisFill.fillOpacity = 1;
      range3.locations.category = 0.5;
      range3.locations.endCategory = 0.5;

      var range4 = radarcategoryAxis.axisRanges.create();
      range4.category = "Social\nResponsibility";
      range4.endCategory = "Impulse\nControl";
      range4.label.disabled = true;
      range4.axisFill.fill = am4core.color("#98f3e1");
      range4.axisFill.fillOpacity = 1;
      range4.locations.category = 0.5;
      range4.locations.endCategory = 0.5;

      var range5 = radarcategoryAxis.axisRanges.create();
      range5.category = "Impulse\nControl";
      range5.endCategory = "Optimism";
      range5.label.disabled = true;
      range5.axisFill.fill = am4core.color("#9acff7");
      range5.axisFill.fillOpacity = 1;
      range5.locations.category = 0.5;
      range5.locations.endCategory = 0.5;

      var range6 = radarcategoryAxis.axisRanges.create();
      range6.category = "Optimism";
      range6.endCategory = "Optimism";
      range6.label.disabled = true;
      range6.axisFill.fill = am4core.color("#f69497");
      range6.axisFill.fillOpacity = 1;
      range6.locations.category = 0.5;
      range6.locations.endCategory = 1;

      // Add series
      var radarseries = chartradar.series.push(new am4charts.RadarSeries());
      radarseries.name = "Organisational Assessment";
      radarseries.dataFields.categoryX = "direction";
      radarseries.dataFields.valueY = "value";
      radarseries.stroke = am4core.color("#000080");
      radarseries.fillOpacity = 0;
      radarseries.stacked = false;

      // Define the range
      var seriesrange = radarvalueAxis.createSeriesRange(radarseries);
      seriesrange.value = 0;
      seriesrange.endValue = 10;

      if(peerselfregard){
        // Add series
        var series2 = chartradar.series.push(new am4charts.RadarSeries());
        series2.name = "Peer-Assessments";
        series2.dataFields.categoryX = "direction";
        series2.dataFields.valueY = "pvalue";
        series2.stroke = am4core.color("#FF0000");
        series2.fillOpacity = 0;
        series2.stacked = false;
      }
      

      // Add Legend
      chartradar.legend = new am4charts.Legend();
      chartradar.legend.position = "top";

      /* Add New legend */
      var radarlegend = new am4charts.Legend();
      radarlegend.parent = chartradar.chartContainer;
      radarlegend.isMeasured = false;
      radarlegend.y = am4core.percent(50);
      radarlegend.verticalCenter = "middle";
      radarlegend.position = "left";
      radarlegend.width = 150;
      radarlegend.data = [{
        "name": "Self-Perception",
        "fill":"#f69497"
      }, {
        "name": "Self-Expresssion",
        "fill": "#fec19b"
      }, {
        "name": "Interpersonal",
        "fill": "#fde79f"
      }, {
        "name": "Decision Making",
        "fill": "#98f3e1"
      }, {
        "name": "Stress Management",
        "fill": "#9acff7"
      }];
      radarlegend.itemContainers.template.clickable = false;
      radarlegend.itemContainers.template.focusable = false;

      // Export
      chartradar.exporting.timeoutDelay = 1500;
      chartradar.exporting.title = assessmentdate;
      chartradar.exporting.menu = new am4core.ExportMenu();
      chartradar.exporting.menu.items = [{
        "label": "...",
        "menu": [
          { "type": "pdf", "label": "PDF" },
          { "type": "jpg", "label": "JPG" },
          { "type": "png", "label": "PNG" }
        ]
      }];
      chartradar.exporting.events.on("exportstarted", function(ev) {
        document.getElementById('amchart1').style.height = 1000 + 'px';
      });

      chartradar.exporting.events.on("exportfinished", function(ev) {
        document.getElementById('amchart1').style.height = 500 + 'px';
      });




      //Chart
      // Create chart instance
      var chart = am4core.create("eqchart", am4charts.XYChart);

      // Add data
      if(peerselfregard){
        chart.data = [{
            "category": "Self Regard",
            "section": 1,
            "self": selfselfregard,
            "peer": peerselfregard,
            "longtext": "<strong>Self-Regard</strong> is respecting oneself while understanding and accepting one’s strengths and weaknesses.<br/> Self-Regard is often associated with feelings of inner strength and self-confidence."
        }, {
            "category": "Self Actualisation",
            "section": 1,
            "self": selfselfactual,
            "peer": peerselfactual,
            "longtext": "<strong>Self-Actualization</strong> is the willingness to persistently try to improve oneself<br/> and engage in the pursuit of personally relevant and meaningful objectives that lead to rich and enjoyable life."
        }, {
            "category": "Emotional Self-Awareness",
            "section": 1,
            "self": selfemoselfaware,
            "peer": peeremoselfaware,
            "longtext": "<strong>Emotional Self-Awareness</strong> includes recognising and understanding your own emotions.<br/> This includes the ability to differentiate between subtleties in one’s own emotions while understanding<br/> the cause of these emotions and the impact they have on your thoughts and actions, and those of others."
        }, {
            "category": "Emotional Expression",
            "section": 2,
            "self": selfemoexpress,
            "peer": peeremoexpress,
            "longtext": "<strong>Emotional Expression</strong> is openly expressing one’s feelings verbally and non-verbally."
        }, {
            "category": "Assertiveness",
            "section": 2,
            "self": selfassert,
            "peer": peerassert,
            "longtext": "<strong>Assertiveness</strong> involves communicating feelings, beliefs and thoughts openly, <br/>and defending personal rights and values in a socially acceptable, non-offensive, and non-destructive manner."  
        }, {
            "category": "Independence",
            "section": 2,
            "self": selfindependance,
            "peer": peerindependance,
            "longtext": "<strong>Independence</strong> is the ability to be self directed and free<br/>from emotional dependency on others. Decision-making, planning, and daily tasks are completed autonomously."
        }, {
            "category": "Interpersonal Relationships",
            "section": 3,
            "self": selfinterrelate,
            "peer": peerinterrelate,
            "longtext": "<strong>Interpersonal Relationships</strong> refers to the skill of developing <br/>and maintaining mutually satisfying relationships that are characterised by trust and compassion."
        }, {
            "category": "Empathy",
            "section": 3,
            "self": selfempathy,
            "peer": peerempathy,
            "longtext": "<strong>Empathy</strong> is recognising, understanding, and appreciating how other people feel.<br/>Empathy involves being able to articulate your understanding of<br/>another’s perspective and behaving in a way that respects others’ feelings."
        }, {
            "category": "Social Responsibility",
            "section": 3,
            "self": selfsocialresp,
            "peer": peersocialresp,
            "longtext": "<strong>Social Responsibility</strong> is willingly contributing to society, to one’s social groups, and generally to the welfare of others.<br/>Social Responsibility involves acting responsibly, having social consciousness, and showing concern for the greater community."
        }, {
            "category": "Problem Solving",
            "section": 4,
            "self": selfproblemsolve,
            "peer": peerproblemsolve,
            "longtext": "<strong>Problem Solving</strong>is the ability to find solutions to problems in situations where emotions are involved.<br/>Problem Solving includes the ability to understand how emotions impact decision making."
        }, {
            "category": "Reality Testing",
            "section": 4,
            "self": selfrealitytest,
            "peer": peerrealitytest,
            "longtext": "<strong>Reality Testing</strong> is the capacity to remain objective by seeing things as they really are.<br/>This capacity involves recognising when emotions or personal bias can cause one to be less objective."
        }, {
            "category": "Impulse Control",
            "section": 4,
            "self": selfimpulsecontrol,
            "peer": peerimpulsecontrol,
            "longtext": "<strong>Impulse Control</strong> is the ability to resist or delay an impulse,<br/>drive or temptation to act, and involves avoiding rash behaviors and decision making."
        }, {
            "category": "Flexibility",
            "section": 5,
            "self":selfflexibility,
            "peer":peerflexibility,
            "longtext": "<strong>Flexibility</strong> is adapting emotions, thoughts, and behaviours to<br/>unfamiliar, unpredictable, and dynamic circumstances or ideas."
        }, {
            "category": "Stress Tolerance",
            "section": 5,
            "self": selfstress,
            "peer": peerstress,
            "longtext": "<strong>Stress Tolerance</strong> involves coping with stressful or difficult situations<br/>and believing that one can manage or influence situations in a positive manner."
        }, { 
            "category": "Optimism",
            "section": 5,
            "self": selfoptimism,
            "peer": peeroptimism,
            "longtext": "<strong>Optimism</strong> is an indicator of one’s positive attitude and outlook on life.<br/>It involves remaining hopeful and resilient despite occasional setbacks."
        }];
      }else{
        chart.data = [{
          "category": "Self Regard",
          "section": 1,
          "self": selfselfregard,
          "longtext": "<strong>Self-Regard</strong> is respecting oneself while understanding and accepting one’s strengths and weaknesses.<br/> Self-Regard is often associated with feelings of inner strength and self-confidence."
        }, {
            "category": "Self Actualisation",
            "section": 1,
            "self": selfselfactual,
            "longtext": "<strong>Self-Actualization</strong> is the willingness to persistently try to improve oneself<br/> and engage in the pursuit of personally relevant and meaningful objectives that lead to rich and enjoyable life."
        }, {
            "category": "Emotional Self-Awareness",
            "section": 1,
            "self": selfemoselfaware,
            "longtext": "<strong>Emotional Self-Awareness</strong> includes recognising and understanding your own emotions.<br/> This includes the ability to differentiate between subtleties in one’s own emotions while understanding<br/> the cause of these emotions and the impact they have on your thoughts and actions, and those of others."
        }, {
            "category": "Emotional Expression",
            "section": 2,
            "self": selfemoexpress,
            "longtext": "<strong>Emotional Expression</strong> is openly expressing one’s feelings verbally and non-verbally."
        }, {
            "category": "Assertiveness",
            "section": 2,
            "self": selfassert,
            "longtext": "<strong>Assertiveness</strong> involves communicating feelings, beliefs and thoughts openly, <br/>and defending personal rights and values in a socially acceptable, non-offensive, and non-destructive manner."  
        }, {
            "category": "Independence",
            "section": 2,
            "self": selfindependance,
            "longtext": "<strong>Independence</strong> is the ability to be self directed and free<br/>from emotional dependency on others. Decision-making, planning, and daily tasks are completed autonomously."
        }, {
            "category": "Interpersonal Relationships",
            "section": 3,
            "self": selfinterrelate,
            "longtext": "<strong>Interpersonal Relationships</strong> refers to the skill of developing <br/>and maintaining mutually satisfying relationships that are characterised by trust and compassion."
        }, {
            "category": "Empathy",
            "section": 3,
            "self": selfempathy,
            "longtext": "<strong>Empathy</strong> is recognising, understanding, and appreciating how other people feel.<br/>Empathy involves being able to articulate your understanding of<br/>another’s perspective and behaving in a way that respects others’ feelings."
        }, {
            "category": "Social Responsibility",
            "section": 3,
            "self": selfsocialresp,
            "longtext": "<strong>Social Responsibility</strong> is willingly contributing to society, to one’s social groups, and generally to the welfare of others.<br/>Social Responsibility involves acting responsibly, having social consciousness, and showing concern for the greater community."
        }, {
            "category": "Problem Solving",
            "section": 4,
            "self": selfproblemsolve,
            "longtext": "<strong>Problem Solving</strong>is the ability to find solutions to problems in situations where emotions are involved.<br/>Problem Solving includes the ability to understand how emotions impact decision making."
        }, {
            "category": "Reality Testing",
            "section": 4,
            "self": selfrealitytest,
            "longtext": "<strong>Reality Testing</strong> is the capacity to remain objective by seeing things as they really are.<br/>This capacity involves recognising when emotions or personal bias can cause one to be less objective."
        }, {
            "category": "Impulse Control",
            "section": 4,
            "self": selfimpulsecontrol,
            "longtext": "<strong>Impulse Control</strong> is the ability to resist or delay an impulse,<br/>drive or temptation to act, and involves avoiding rash behaviors and decision making."
        }, {
            "category": "Flexibility",
            "section": 5,
            "self":selfflexibility,
            "longtext": "<strong>Flexibility</strong> is adapting emotions, thoughts, and behaviours to<br/>unfamiliar, unpredictable, and dynamic circumstances or ideas."
        }, {
            "category": "Stress Tolerance",
            "section": 5,
            "self": selfstress,
            "longtext": "<strong>Stress Tolerance</strong> involves coping with stressful or difficult situations<br/>and believing that one can manage or influence situations in a positive manner."
        }, { 
            "category": "Optimism",
            "section": 5,
            "self": selfoptimism,
            "longtext": "<strong>Optimism</strong> is an indicator of one’s positive attitude and outlook on life.<br/>It involves remaining hopeful and resilient despite occasional setbacks."
        }];
      }

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererCircular>());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      //categoryAxis.autoWrap = true;
      //categoryAxis.textAlign = "middle";
      categoryAxis.renderer.grid.template.disabled = true;
      //categoryAxis.labelsEnabled = false;
      categoryAxis.renderer.labels.template.rotation = 60;
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.horizontalCenter = "middle";

      var label = categoryAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 120;
      //label.textAlign = "left";
      label.tooltipHTML = "{longtext}";

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererRadial>());
      valueAxis.title.text = "Score";
      //valueAxis.title.fontWeight = 800;
      valueAxis.min = 0;
      valueAxis.max = 10;
      valueAxis.renderer.grid.template.disabled = true;

      // Create series
      var series: any = chart.series.push(new am4charts.ColumnSeries());
      series.name = "Areas";
      series.dataFields.valueY = "self";
      series.dataFields.categoryX = "category";
      series.dataFields.section = "section";
      series.columns.template.stroke = am4core.color("#fff");
      series.columns.template.adapter.add("fill", function(fill, target) {
        if (target.dataItem && (target.dataItem.section == 1)) {
          return am4core.color("#f69497");
        } else if (target.dataItem && (target.dataItem.section == 2)) {
          return am4core.color("#fec19b");    
        } else if (target.dataItem && (target.dataItem.section == 3)) {
          return am4core.color("#fde79f");    
        } else if (target.dataItem && (target.dataItem.section == 4)) {
          return am4core.color("#98f3e1");    
        } else {
          return  am4core.color("#9acff7");
        }
      });

      //Self Assessment Line
      var lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Self-Assessment";
      lineSeries.dataFields.valueY = "self";
      lineSeries.dataFields.categoryX = "category";

      lineSeries.stroke = am4core.color("#2288c2");
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";

      var bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default

      chart.maskBullets = false;

      if(peerselfregard){
        // Peer Assessment Line
        var lineSeries2 = chart.series.push(new am4charts.LineSeries());
        lineSeries2.name = "Peer-Assessment";
        lineSeries2.dataFields.valueY = "peer";
        lineSeries2.dataFields.categoryX = "category";

        lineSeries2.stroke = am4core.color("#e4373a");
        lineSeries2.strokeWidth = 3;
        lineSeries2.propertyFields.strokeDasharray = "lineDash";
        lineSeries2.tooltip.label.textAlign = "middle";
      }

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;

      var legend1 = new am4charts.Legend();
      legend1.parent = chart.chartContainer;
      legend1.isMeasured = false;
      legend1.y = am4core.percent(0);
      legend1.verticalCenter = "middle";
      legend1.position = "top";
      if(peerselfregard){
        legend1.data = [{
          "name": "Self-Assessment",
          "fill":"#2288c2"
        }, {
          "name": "Peer Assessment",
          "fill": "#e4373a"
        }];
      }else{
        legend1.data = [{
          "name": "Self-Assessment",
          "fill":"#2288c2"
        }];
      }
      legend1.itemContainers.template.clickable = false;
      legend1.itemContainers.template.focusable = false;
      legend1.itemContainers.template.width = 170;

      // Export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.timeoutDelay = 1500;
      chart.exporting.title = assessmentdate;
      chart.exporting.menu.items = [{
        "label": "...",
        "menu": [
          { "type": "pdf", "label": "PDF" },
          { "type": "jpg", "label": "JPG" },
          { "type": "png", "label": "PNG" }
        ]
      }];
    });
  }

  toggleCharts(chartname: string){
    if(chartname == 'eqgraph'){
      this.eqChartShow = true;
      this.eqGraphShow = false;
    }else if(chartname == 'eqchart'){
      this.eqChartShow = false;
      this.eqGraphShow = true;
    }
  }

}