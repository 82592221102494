import { Component, EventEmitter, OnInit, Output, AfterViewInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportState } from 'src/app/state/report.state';
import { UpdateReportLoading } from 'src/app/state/ui.state';

@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss']
})
export class FilterContainerComponent implements OnInit {
  displayEQData: string = 'none';
  displayEngagementBreakdown: boolean = false;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;
  
  constructor(private store: Store) { }

  ngOnInit() {
    this.reportInfo$
    .subscribe(reportdata => {
      // check if we can display eq data
      if(reportdata !== null && reportdata.CompanyEQData){
        // if SelfandPeer
        if(
          reportdata.CompanyEQData.SelfAndPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] >= 5
        ){
          this.displayEQData = 'SelfAndPeer';
        }else if(
          (reportdata.CompanyEQData.SelfOnly && 
          reportdata.CompanyEQData.TotalSelfOnly && 
          !reportdata.CompanyEQData.SelfAndPeer && 
          !reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfOnly[1] >= 5) 
          ||
          (reportdata.CompanyEQData.SelfOnly && 
          reportdata.CompanyEQData.TotalSelfOnly && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer && 
          reportdata.CompanyEQData.TotalSelfAssessmentswithPeer[1] <= 5 &&
          reportdata.CompanyEQData.TotalSelfOnly[1] >= 5)
        ){
          this.displayEQData = 'SelfOnly';
        }
      }
      if(
        (
          reportdata !== null && 
          reportdata.users !== undefined && 
          reportdata.users && Object.keys(reportdata.users).length > 1
        ) 
        ||
        (
          reportdata !== null && 
          reportdata.users !== undefined && 
          reportdata.CompanyEQData.averageGrowthScore &&
          reportdata.CompanyEQData.averageGrowthScore > 0
        )
        ){
          let engagement = [];
          for (const [key, value] of Object.entries(reportdata.users)) {
            engagement.push(reportdata.users[key].engagement);
          }
          //  if more than one user and enagement values are different
          this.displayEngagementBreakdown = (engagement.every( (val, i, arr) => val === arr[0] )) ? false : true;
      }
    });
  }

}
