import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  //baseURI: string = 'https://fccwip.co.za:443/api';
  //baseURI: string = 'http://phpstack-556615-1827287.cloudwaysapps.com/api';
  baseURI: string = 'https://progressbackend.mygrow.me/api';

  constructor(private http: HttpClient, private store: Store) { }

  getReportHierarchy(){
    return this.http.get<any>(this.baseURI+'/mygrow_companies/all');
  }

  getReport(query: string, timeframe: number = 0){
    return this.http.get<any>(this.baseURI+'/mygrow_reportapitime/detail?'+query+'&timetrack='+timeframe);
  }

  getImpactReport(query: string){
    return this.http.get<any>(this.baseURI+'/mygrow_eomapi/eomuserdata?'+query);
  }

  getModulesInfo(){
    return this.http.get<any>(this.baseURI+'/mygrow_eomapi/moduledetail');
  }

  getComparisons(query: string){
    return this.http.get<any>(this.baseURI+'/mygrow_reportcompareapitime/detail?'+query);
  }

}
