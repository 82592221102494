import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { NavComparisonPayload, NavStateModel, NavTimeFramePayload } from '../interfaces/app.interfaces';

export class UpdateNavComparison {
    static readonly type = '[Nav] update comparison';
    constructor(public payload: NavComparisonPayload) { }
}

export class UpdateTimeFilter {
  static readonly type = '[Nav] update timefilter';
  constructor(public payload: NavTimeFramePayload) { }
}

//state
@State<NavStateModel>({
  name: 'reportingnav',
  defaults: {
    comparison: 'none',
    timefilter: 0
  }
})
export class NavState {
  constructor() { }

  // Selectors

  @Selector()
  public static navTimeFilter(state: NavStateModel) {
    return state.timefilter;
  }

  @Selector()
  public static navComparison(state: NavStateModel) {
    return state.comparison;
  }

  //Actions

  @Action(UpdateNavComparison)
  updateNavComparison(ctx: StateContext<NavStateModel>, action: UpdateNavComparison) {
    ctx.patchState({
        comparison: action.payload.comparison
    });
  }

  @Action(UpdateTimeFilter)
  updateTimeFilter(ctx: StateContext<NavStateModel>, action: UpdateTimeFilter) {
    ctx.patchState({
      timefilter: action.payload.timeframe
    });
  }

}