import { createHostListener } from '@angular/compiler/src/core';
import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, UserStateModel } from './interfaces/app.interfaces';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { UpdateUserInfo, UserState } from './state/user.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy{
  userid: string;

  private ngUnsubscribe = new Subject();

  @Select(UserState.user) user$: Observable<UserStateModel>;

  constructor(
    private router: Router,
    private authService: AuthService, 
    private userService: UserService,
    private store: Store,
    private gtmService: GoogleTagManagerService
    ){
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // code to run every time a router event occurs (or page reloads in browser)
        // check id userid > get user data
        this.checkCookieForUserid();
      }
    });
    this.gtmService.addGtmToDom();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkCookieForUserid(){
    this.userid = this.authService.getUserid();
    
    if(this.userid){
      // we have an id and we do not have userdata so get user data
      this.getUserData(this.userid);
    }else{
      // no userid so logout
      this.authService.logout();
    }
  }

  getUserData(userid: string){
    if(userid !== undefined){
      this.userService.getUserDetail(userid)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        if(res.data){
          let companies = (res.data.mygrow_reportusers[0].level === 'Company') ? this.userService.processCompanies(res.data.mygrow_reportusers[0].companies) : [];
          this.updateUserInfo(
            res.data.mygrow_reportusers[0].id, 
            res.data.mygrow_reportusers[0].full_name,
            res.data.mygrow_reportusers[0].last_name,
            res.data.mygrow_reportusers[0].level,
            res.data.mygrow_reportusers[0].email,
            companies
          );
        }
      });
    }
  }

  updateUserInfo(
    id: string,
    first_name: string,
    last_name: string,
    level: string,
    email: string,
    companies: Company[]){
    this.store.dispatch(new UpdateUserInfo({ 
      'id' : id, 
      'first_name' : first_name,
      'last_name' : last_name,
      'level' : level,
      'email' : email,
      'companies' : companies
    }));
  }

}
