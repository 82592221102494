import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportState } from 'src/app/state/report.state';
import { moduleIDOrder } from '../../../../constants';


@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.scss']
})
export class ImpactComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  impactData: Object[] = null;
  activeImpact: number = 0;

  @Select(ReportState.reportInfo) reportInfo$: Observable<any>;
  @Select(ReportState.modulesInfo) modulesInfo$: Observable<any>;
  @Select(ReportState.impactReportInfo) impactReportInfo$: Observable<any>;

  constructor() {
  }

  ngOnInit() {
    combineLatest(this.modulesInfo$, this.impactReportInfo$)
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(([modulesInfo, impactReportInfo]) => {

      if(modulesInfo && impactReportInfo){
        this.impactData = moduleIDOrder.map(moduleid => {
          let thismodule = null;
          if(modulesInfo[1495].modules[moduleid]){
            thismodule = modulesInfo[1495].modules[moduleid];
          }else if(modulesInfo[5066].modules[moduleid]){
            thismodule = modulesInfo[5066].modules[moduleid];
          }
          if(thismodule){
            return thismodule;
          }
        });
      }
      
      if(impactReportInfo){
        Object.keys(impactReportInfo).forEach(key => {
          if(this.impactData[(Number(key) - 1)]){
            this.impactData[(Number(key) - 1)]['averageduration'] = impactReportInfo[key].averageduration;
            this.impactData[(Number(key) - 1)]['numcompleted'] =  impactReportInfo[key].numcompleted;
            this.impactData[(Number(key) - 1)]['numstarted'] =  impactReportInfo[key].numstarted;
            this.impactData[(Number(key) - 1)]['skills_developed'] =  impactReportInfo[key].skills_developed;
            this.impactData[(Number(key) - 1)]['surveycount'] =  impactReportInfo[key].surveycount;
            this.impactData[(Number(key) - 1)]['module_skills'] = this.impactData[(Number(key) - 1)]['module_skills'].map(moduleskill => {
              if(impactReportInfo[key].skills_developed && impactReportInfo[key].skills_developed[moduleskill.name]){
                moduleskill.growth = impactReportInfo[key].skills_developed[moduleskill.name];
                return moduleskill;
              }else{
                return moduleskill;
              }
            });
          };
        });
      }else{
        this.impactData = null;
      }
      
      console.log(this.impactData);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  stripTags(text: string){
    return text.replace(/(<([^>]+)>)/gi, "");
  }

  getCompletionRate(started: number,completed: number){
    let value = (completed / started) * 100;
    return (completed > 0 && started > 0) ? value.toFixed(0) : 0;
  }

  getPrecGrowth(value: number){
    let tempnum = value.toFixed(1);
    return tempnum+'%';
  }

  getPrecGrowthtoDec(value: number){
   return value.toFixed(1);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  activateImpact(index: number){
    this.activeImpact = index;
    // let el = document.getElementById('tab'+index);
    // this.scroll(el);
  }

}
